import * as Pages from "pages";
import { CompanyTable } from "components";
import { routes } from "configs";
import { dashboardHOC } from "hocs";
import { AccountSlug } from "types";

export const ACCOUNT_SLUGS: AccountSlug[] = ["pimwacorp"];

export const OPEN_ROUTES = [
  {
    path: ACCOUNT_SLUGS.map(routes.register),
    Component: Pages.Register,
  },
];

export const SECURED_ROUTES = [
  {
    path: routes.dashboard,
    Component: Pages.Dashboard,
  },
  {
    path: routes.discretionaryTrust.details(),
    Component: dashboardHOC<any>({
      Component: Pages.DiscretionaryTrustDetails,
      title: "discretionaryTrust.details",
    }),
  },
  {
    path: routes.discretionaryTrust.list(),
    Component: dashboardHOC<any>({
      Component: Pages.DiscretionaryTrustList,
      title: "common.list",
    }),
  },
  {
    path: routes.discretionaryTrust.create(),
    Component: dashboardHOC<any>({
      Component: Pages.DiscretionaryTrustCreate,
      title: "discretionaryTrust.create",
    }),
  },
  {
    path: routes.company.list(),
    Component: dashboardHOC<any>({
      Component: CompanyTable,
      title: "company.my",
    }),
  },
  {
    path: routes.company.view(),
    Component: dashboardHOC({
      Component: Pages.companies.CompanyDetails,
      title: "common.details",
    }),
  },
  {
    path: routes.company.about(),
    Component: dashboardHOC({
      Component: Pages.companies.CompanyAbout,
      title: "common.about",
    }),
  },
  {
    path: routes.company.create(),
    Component: dashboardHOC({
      Component: Pages.companies.CompanyCreate,
      title: "company.createNew",
    }),
  },
  {
    path: routes.about,
    Component: dashboardHOC({
      Component: Pages.About,
      title: "common.about",
    }),
  },
  {
    path: routes.settings.userProfile(),
    Component: dashboardHOC({
      Component: Pages.settings.ClientProfile,
      title: "settings.userProfile.title",
    }),
  },
];

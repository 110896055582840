import { FC, ChangeEvent } from "react";
import { Form, Input, FormItemProps, InputRef } from "antd";
import { XOR } from "types";
import { FormattedMessage } from "react-intl";

interface InputPasswordProps
  extends Pick<FormItemProps, "rules" | "name" | "noStyle"> {
  placeholder?: string;
  tooltip?: string;
  label?: string;
  className?: string;
  required?: boolean;
  autoFocus?: boolean;
  isDuplicate?: boolean;
  preserve?: boolean;
  innerRef?: React.MutableRefObject<InputRef | null>;
  dependencies?: string[];
}

interface NotFormItemProps extends InputPasswordProps {
  isFormItem: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const InputPassword: FC<XOR<InputPasswordProps, NotFormItemProps>> = ({
  name,
  placeholder = "",
  rules = [],
  required,
  label,
  innerRef,
  autoFocus,
  isDuplicate,
  className,
  noStyle,
  tooltip,
  isFormItem = true,
  preserve,
  ...props
}) => {
  const input = (
    <Input.Password
      {...props}
      autoFocus={autoFocus}
      placeholder={placeholder}
      autoComplete="new-password"
      ref={innerRef}
    />
  );

  if (!isFormItem) {
    return input;
  }

  return (
    <Form.Item
      label={label && <FormattedMessage id={label} />}
      name={name}
      noStyle={noStyle}
      preserve={preserve}
      className={className}
      tooltip={tooltip}
      rules={[
        {
          required,
          message: <FormattedMessage id="validation.required" />,
        },
        {
          validator: (_, value) => {
            const reg =
              // eslint-disable-next-line
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{8,99}$/;

            if ((!reg.test(value) || /&/g.test(value)) && value) {
              return Promise.reject(
                "8 characters long including uppercase, lowercase, a number and a symbol = + - ^ $ . [ ] { } ( ) ! @ # % _ ~",
              );
            }

            return Promise.resolve();
          },
        },
        {
          validator() {
            return isDuplicate
              ? Promise.reject("Already exists")
              : Promise.resolve();
          },
        },
        ...rules,
      ]}
    >
      {input}
    </Form.Item>
  );
};
export default InputPassword;

import { FC, ReactElement, useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Form, notification } from "antd";
import { TotalPrice } from "./components";

interface CompletePaymentProps {
  total: number;
  children: (args: { loading: boolean; disabled: boolean }) => ReactElement;
}

const CompletePayment: FC<CompletePaymentProps> = ({ children, total }) => {
  const [loading, toggleLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  return (
    <Form
      layout="vertical"
      onFinish={async () => {
        if (!stripe || !elements) {
          notification.error({
            message: "Payment system error",
          });
          return;
        }

        toggleLoading(true);

        try {
          const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
              return_url: window.location.href,
            },
          });

          if (error) {
            notification.error({
              message: error.message,
            });
          }
        } catch (e: any) {
          notification.error({
            message: e.message,
          });
        }

        toggleLoading(false);
      }}
    >
      <TotalPrice total={total} />
      <PaymentElement />

      {children({
        loading,
        disabled: !stripe || !elements,
      })}
    </Form>
  );
};

export default CompletePayment;

import { useQuery } from "./tanstack";
import { useSelector } from "./useReactRedux";
import { getIndividualsQueryKey } from "utils";
import { Individual } from "types";

const useIndividuals = (disabled?: boolean) => {
  const cognitoID = useSelector((state) => state.auth.client?.cognitoID);
  const queryKey = getIndividualsQueryKey(cognitoID as string);
  const { data, isLoading } = useQuery<Individual[]>({
    apiName: "client",
    path: `/${cognitoID}/individuals`,
    enabled: !!cognitoID && !disabled,
    queryKey,
  });

  return {
    individuals: data,
    individualsLoading: isLoading,
    individualsQueryKey: queryKey,
  };
};

export default useIndividuals;

import * as React from "react";
import { Skeleton, Row, Col, StepsSkeleton } from "components";
import { COMMON_COL_PROPS, ROW_GUTTER } from "consts";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import { constants } from "../../duck";

const useStyles = createUseStyles({
  common: {
    height: 20,
    marginBottom: 24,
  },
  input: {
    "&.ant-skeleton.ant-skeleton-element ": {
      "& .ant-skeleton-input": {
        width: 400,
      },
    },
  },
});

const DiscretionaryTrustSkeleton: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <StepsSkeleton steps={constants.STEPS} />
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <Skeleton title active paragraph={{ rows: 0 }} />
          <Skeleton.Input
            active
            size="small"
            className={classNames(classes.common, classes.input)}
          />
          <Skeleton title active paragraph={{ rows: 0 }} />
          <Skeleton.Input
            active
            size="small"
            className={classNames(classes.common, classes.input)}
          />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <Skeleton title active paragraph={{ rows: 0 }} />
          <Skeleton.Input
            active
            size="small"
            className={classNames(classes.common, classes.input)}
          />
        </Col>
      </Row>
    </>
  );
};

export default DiscretionaryTrustSkeleton;

import { FC } from "react";
import {
  Drawer,
  Form,
  Button,
  AsyncSelect,
  InputSelect,
  InputGroup,
  FormItemInputNumber,
  IndividualSelect,
  CompanySelect,
  Typography,
  Divider,
} from "components";
import { useMutation, useQuery } from "hooks";
import { getQueryKey } from "utils";
import { FormattedMessage, useIntl } from "react-intl";
import { selectors } from "../../../../duck";
import { ShareCapital, ShareholderType, Shareholder } from "types";

interface AddNewDrawerProps {
  companyID: number;
  cognitoID: string;
  onClose: () => void;
  open: boolean;
  queryKey: string[];
  shareholders: Shareholder[];
}

const AddNewDrawer: FC<AddNewDrawerProps> = ({
  open,
  companyID,
  cognitoID,
  onClose,
  queryKey,
  shareholders,
}) => {
  const [form] = Form.useForm();
  const intl = useIntl();

  const shareCapitalQueryKey = getQueryKey("companyShareCapital", companyID);
  const { isPending, mutate } = useMutation({
    method: "post",
    apiName: "client",
    invalidateQueries: [queryKey, shareCapitalQueryKey],
    path: selectors.getUrlPath({
      cognitoID,
      companyID,
      path: "shareholders",
    }),
    onSuccess: () => {
      onClose();
      form.resetFields();
    },
  });

  const { data: shareCapitalList = [], isLoading } = useQuery<ShareCapital[]>({
    apiName: "client",
    queryKey: shareCapitalQueryKey,
    path: selectors.getUrlPath({
      cognitoID,
      companyID,
      path: "share-capital",
    }),
    select: (data) =>
      data.reduce((prev, curr) => {
        const sharesIssued = shareholders
          .filter((item) => item.shareClassID === curr.shareClassID)
          .reduce(
            (result, shareholder) => result + shareholder.sharesIssued,
            0,
          );

        return [
          ...prev,
          {
            ...curr,
            quantity: curr.quantity - sharesIssued,
          },
        ];
      }, [] as ShareCapital[]),
  });

  return (
    <Drawer
      open={open}
      destroyOnClose
      onClose={onClose}
      title={<FormattedMessage id="company.fiveStep.addShareholder" />}
      footer={
        <Button
          type="primary"
          loading={isPending}
          onClick={() => form.submit()}
        >
          Submit
        </Button>
      }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={mutate}
        onValuesChange={(values) => {
          const [name] = Object.keys(values);

          if (name === "shareClassID") {
            form.validateFields(["sharesIssued"]);
          }
        }}
        initialValues={{
          beneficiallyHeld: true,
        }}
      >
        <Typography.Title level={5}>1. Select Shareholder</Typography.Title>
        <Typography.Paragraph>
          Select if your Shareholder is an Individual or a Company.
        </Typography.Paragraph>
        <AsyncSelect<ShareholderType>
          required
          label="common.type"
          name="typeID"
          queryKey={getQueryKey("shareholdersType")}
          apiName="system"
          path="/shareholder-types"
          getOptionProps={(opt) => ({
            value: opt.id,
            children: opt.type,
          })}
        />
        <Form.Item dependencies={["typeID"]} noStyle>
          {({ getFieldValue }) => {
            const value = getFieldValue("typeID");

            if (!value) {
              return null;
            }

            if (value === 1) {
              return (
                <>
                  <Typography.Paragraph>
                    Select an existing individual from the list below, or press
                    &quot;+&quot; to add a new individual.
                  </Typography.Paragraph>
                  <IndividualSelect
                    parentForm={form}
                    drawerRequiredFields={{
                      firstName: true,
                      lastName: true,
                      addressID: true,
                      dateOfBirth: true,
                      placeOfBirthCity: true,
                      placeOfBirthCountryID: true,
                    }}
                  />
                </>
              );
            }

            return (
              <>
                <Typography.Paragraph>
                  Select an existing company from the list below, or press
                  &quot;+&quot; to add a new company.
                </Typography.Paragraph>
                <CompanySelect
                  parentForm={form}
                  drawerRequiredFields={{
                    name: true,
                    acn: true,
                    typeID: true,
                  }}
                />
              </>
            );
          }}
        </Form.Item>
        <Divider />
        <Typography.Title level={5}>2. Beneficially Held?</Typography.Title>
        <InputGroup
          optionType="default"
          name="beneficiallyHeld"
          label="company.fiveStep.beneficiallyHeld"
        />
        <Divider />
        <Typography.Title level={5}>3. Allocate the Shares</Typography.Title>
        <InputSelect
          required
          loading={isLoading}
          options={shareCapitalList}
          label="company.shareClass"
          name="shareClassID"
          getOptionProps={(opt) => ({
            value: opt.shareClassID,
            disabled: !opt.quantity,
            children: intl.formatMessage(
              { id: "company.fiveStep.shareClassOption" },
              {
                shareCode: opt.shareCode,
                quantity: opt.quantity,
              },
            ),
          })}
        />
        <Form.Item dependencies={["shareClassID"]} noStyle>
          {({ getFieldValue }) => {
            const value = getFieldValue("shareClassID");
            const shareCapital = shareCapitalList.find(
              (item) => item.shareClassID === value,
            );

            return (
              <FormItemInputNumber
                name="sharesIssued"
                label="company.fiveStep.numberOfSharesIssued"
                greaterThan={0}
                lessThan={shareCapital?.quantity}
                required
              />
            );
          }}
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddNewDrawer;

import { FC, ReactNode } from "react";
import { Alert, FormattedMessage } from "components";
import { createUseStyles } from "react-jss";

interface StepInstructionsProps {
  currentStep: number;
  title: string;
  description?: string;
  descriptionValues?: Record<string, ReactNode>;
}

const useStyles = createUseStyles({
  alert: {
    marginBottom: 24,
  },
});

const StepInstructions: FC<StepInstructionsProps> = ({
  currentStep,
  title,
  description,
  descriptionValues,
}) => {
  const classes = useStyles();

  return (
    <Alert
      type="info"
      className={classes.alert}
      message={
        <>
          <FormattedMessage
            id="common.step"
            values={{ step: currentStep + 1 }}
          />
          &nbsp; - &nbsp;
          <FormattedMessage id={title} />
        </>
      }
      description={
        description && (
          <FormattedMessage
            id={description}
            values={{
              ...descriptionValues,
              br: (
                <>
                  <br />
                  <br />
                </>
              ),
              b: (chunks) => <strong>{chunks}</strong>,
            }}
          />
        )
      }
    />
  );
};

export default StepInstructions;

import { FC } from "react";
import { Button, Col, Drawer, Form, Row, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import GoogleAutocomplete from "components/google-autocomplete";
import InputText from "../../../input-text";
import AsyncSelect from "../../async-select";
import { ROW_GUTTER } from "consts";
import { Address, CommonRecord, Country } from "types";
import { getQueryKey } from "utils";
import { createUseStyles } from "react-jss";
import { useMutation, useSelector } from "hooks";

const useStyles = createUseStyles({
  streetType: {
    overflow: "hidden",
  },
});

interface AddressDrawerProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (addressID: number) => void;
}

const AddressDrawer: FC<AddressDrawerProps> = ({
  open,
  onSuccess,
  onClose,
}) => {
  const classes = useStyles();
  const clientID = useSelector((state) => state.auth.client?.id as number);
  const [form] = Form.useForm();
  const { isPending, mutate } = useMutation<Address>({
    invalidateQueries: [getQueryKey("clientAddresses", clientID)],
    method: "post",
    apiName: "client",
    // TODO Replace with cognitoID
    path: `/${clientID}/address`,
    onSuccess: (newData) => {
      onSuccess(newData.id);
      onClose();
    },
  });

  return (
    <Drawer
      open={open}
      onClose={onClose}
      width="500"
      destroyOnClose
      title={<FormattedMessage id="address.title" />}
      footer={
        <Button
          type="primary"
          loading={isPending}
          onClick={() => form.submit()}
        >
          Submit
        </Button>
      }
    >
      <Form form={form} layout="vertical" preserve={false} onFinish={mutate}>
        <Typography.Title level={5} style={{ marginTop: 0 }}>
          <FormattedMessage id="address.title" />
        </Typography.Title>
        <Form.Item
          label={<FormattedMessage id="address.location" />}
          shouldUpdate
        >
          {({ setFieldsValue }) => (
            <GoogleAutocomplete
              onChange={(addresses) => {
                setFieldsValue(addresses);
              }}
            />
          )}
        </Form.Item>

        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={12}>
            <InputText label="address.unitNumber" name="unitNumber" />
          </Col>
          <Col xs={24} sm={12}>
            <InputText label="address.streetNumber" name="streetNumber" />
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={16}>
            <InputText label="address.streetName" name="streetName" required />
          </Col>
          <Col xs={24} sm={8} className={classes.streetType}>
            <AsyncSelect<CommonRecord>
              required
              label="address.streetType"
              name="streetTypeID"
              apiName="system"
              path="/street-types"
              queryKey={getQueryKey("streetTypes")}
              getOptionProps={(opt) => ({
                value: opt.id,
                children: opt.name,
              })}
            />
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={16}>
            <InputText required label="address.suburb" name="suburb" />
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={12}>
            <InputText required label="address.state" name="state" />
          </Col>
          <Col xs={24} sm={12}>
            <InputText required label="address.postcode" name="postcode" />
          </Col>
        </Row>
        <AsyncSelect<Country>
          required
          name="countryID"
          label="common.country"
          apiName="system"
          path="/countries"
          queryKey={getQueryKey("countries")}
          getOptionProps={(opt) => ({
            value: opt.id,
            children: opt.full_name,
          })}
        />
      </Form>
    </Drawer>
  );
};

export default AddressDrawer;

import { FC } from "react";
import { Form, Button, Drawer } from "antd";
import { FormattedMessage } from "react-intl";
import InputText from "components/input-text";
import AsyncSelect from "../../../async-select";
import AddressSelect from "../../../address-select";
import { CompanyType, Company, Jurisdiction, QueryKey } from "types";
import { getQueryKey } from "utils";
import { useMutation, useSelector } from "hooks";
import { routes } from "configs";
import { CompanySelectProps, DrawerRequiredFields } from "../../types";

interface AddCompanyDrawerProps extends Pick<CompanySelectProps, "parentForm"> {
  open: boolean;
  formInputName: string;
  onClose: () => void;
  queryKey: QueryKey;
  requiredFields?: DrawerRequiredFields;
  multi?: boolean;
}

const AddCompanyDrawer: FC<AddCompanyDrawerProps> = ({
  open,
  parentForm,
  onClose,
  queryKey,
  formInputName,
  requiredFields,
  multi,
}) => {
  const [form] = Form.useForm();
  const cognitoID = useSelector((state) => state.auth.client?.cognitoID);
  const { isPending, mutate } = useMutation<Company>({
    apiName: "client",
    path: `/${cognitoID}/companies/registered`,
    method: "post",
    invalidateQueries: [queryKey],
    onSuccess: (newData) => {
      onClose();

      parentForm.setFields([
        {
          name: formInputName,
          value: !multi
            ? newData.id
            : [...parentForm.getFieldValue(formInputName), newData.id],
          touched: true,
        },
      ]);

      form.resetFields();
    },
  });

  return (
    <Drawer
      open={open}
      destroyOnClose
      onClose={onClose}
      title={<FormattedMessage id="company.add" />}
      footer={
        <Button
          type="primary"
          loading={isPending}
          onClick={() => form.submit()}
        >
          Submit
        </Button>
      }
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => {
          mutate(values);
        }}
      >
        <InputText
          required={requiredFields?.name}
          name="name"
          label="company.name"
        />
        <InputText
          required={requiredFields?.acn}
          name="acn"
          label="company.acn"
        />
        <AsyncSelect<CompanyType>
          required={requiredFields?.typeID}
          name="typeID"
          label="company.type"
          queryKey={getQueryKey("companyTypes")}
          apiName="companies"
          path={routes.company.types()}
          getOptionProps={(opt) => ({
            value: opt.id,
            children: opt.description,
          })}
        />
        <AsyncSelect<Jurisdiction>
          required={requiredFields?.jurisdictionID}
          name="jurisdictionID"
          label="common.jurisdiction"
          apiName="system"
          path="/jurisdictions"
          queryKey={getQueryKey("jurisdictions")}
          getOptionProps={(opt) => ({
            value: opt.id,
            children: opt.name,
          })}
        />
        <AddressSelect
          label="company.secondStep.registeredOffice"
          name="registeredOfficeID"
          parentForm={form}
        />
      </Form>
    </Drawer>
  );
};

export default AddCompanyDrawer;

import { useEffect } from "react";
import flatten from "flat";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "react-jss";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, PreLoader, Grid } from "components";
import { authActions, appStateActions } from "configs";
import { useDispatch, useSelector } from "hooks";
import Layout from "layout";
import messages from "translations";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60,
    },
  },
});

const theme = {
  label_font_weight: 500,
};

const App = () => {
  const breakpoint = Grid.useBreakpoint();
  const dispatch = useDispatch();

  const authenticating = useSelector((state) => state.auth.authenticating);

  useEffect(() => {
    dispatch(authActions.authenticateStart());
  }, [dispatch]);

  useEffect(() => {
    dispatch(appStateActions.setBreakpoints(breakpoint));
  }, [breakpoint, dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <IntlProvider locale="en" messages={flatten(messages.en)}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <BrowserRouter>
            {authenticating ? <PreLoader size="large" /> : <Layout />}
          </BrowserRouter>
        </QueryClientProvider>
      </IntlProvider>
    </ThemeProvider>
  );
};

export default App;

import { QueryKeys, QueryKey } from "types";

export const getQueryKey = (key: keyof QueryKeys, ...ids: QueryKey) => {
  const joined = ids.join(", ");

  const result: string[] = [key];

  if (joined) {
    result.push(joined);
  }

  return result;
};

export const getIndividualsQueryKey = (cognitoID: string) =>
  getQueryKey("clientIndividuals", cognitoID);

export const getCompaniesQueryKey = (cognitoID: string) =>
  getQueryKey("companyList", cognitoID);

import { FC } from "react";
import { ExtendedTable, NavLink, StatusTag } from "components";
import { useSelector, useQuery } from "hooks";
import { getQueryKey } from "utils";
import { routes } from "configs";
import { TrustDiscretionary } from "types";
import _uniqBy from "lodash/uniqBy";
import dayjs from "dayjs";
import { DATE_FORMAT } from "consts";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

interface TrustDiscretionaryListItem extends TrustDiscretionary {
  trusteeType: string;
  status: string;
}

const useStyles = createUseStyles({
  row: {
    cursor: "pointer",
  },
});

const DiscretionaryTrustList: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const cognitoID = useSelector(
    (state) => state.auth.client?.cognitoID as string,
  );

  const queryKey = getQueryKey("discretionaryTrustList", cognitoID);

  const { data = [], isLoading } = useQuery<TrustDiscretionaryListItem[]>({
    apiName: "client",
    queryKey,
    path: `/${cognitoID}/discretionary-trust`,
  });

  return (
    <ExtendedTable<TrustDiscretionaryListItem>
      loading={isLoading}
      dataSource={data}
      onRow={(record) => ({
        className: classes.row,
        onClick: () => navigate(routes.discretionaryTrust.details(record.id)),
      })}
      columns={[
        {
          dataIndex: "trustName",
          title: "common.trustName",
          render: ({ value, record }) => (
            <NavLink to={routes.discretionaryTrust.details(record.id)}>
              {value}
            </NavLink>
          ),
        },
        {
          title: "common.status",
          dataIndex: "status",
          filters: _uniqBy(data, "status").map((item) => ({
            value: item.statusID,
            text: item.status,
          })),
          onFilter: (value, record) =>
            value.toString() === record.statusID.toString(),
          render: ({ record: { statusID, status } }) => (
            <StatusTag id={statusID} name={status} />
          ),
        },
        {
          title: "discretionaryTrust.deedDate",
          dataIndex: "deedDate",
          render: ({ value }) => dayjs(value).format(DATE_FORMAT),
        },
      ]}
    />
  );
};

export default DiscretionaryTrustList;

import {
  Button,
  Row,
  Col,
  Divider,
  Image,
  Typography,
  FormattedMessage,
  CompanyTable,
} from "components";
import { gtm, routes } from "configs";
import { useNavigate } from "hooks";
import { ROW_GUTTER, COMMON_COL_PROPS } from "consts";
import { dashboardLogo } from "assets";

const DashboardPage = () => {
  const navigate = useNavigate();

  const onButtonClick = () => {
    const eventTracking = {
      event: "dashboard_create_company_click",
      label: routes.company.create(),
    };

    gtm.dataLayer({
      dataLayer: eventTracking,
    });

    navigate(routes.company.create());
  };

  return (
    <>
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <Image src={dashboardLogo} preview={false} />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <Typography.Title level={2}>
            <FormattedMessage id="dashboard.welcome" />
          </Typography.Title>
          <Divider />
          <Typography.Paragraph>
            <FormattedMessage
              id="dashboard.description"
              values={{
                b: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          </Typography.Paragraph>
          <Typography.Paragraph>
            <FormattedMessage
              id="dashboard.subDescription"
              values={{
                b: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          </Typography.Paragraph>
          <Divider />
          <Button
            style={{ width: "300px", marginTop: 4 }}
            type="primary"
            onClick={onButtonClick}
          >
            <strong>Create New Company</strong> - $647.00
          </Button>
          <br />
          <Button style={{ width: "300px", marginTop: 4 }} disabled>
            <strong>Setup New Trust</strong> - $coming soon
          </Button>
          <br />
          <Button style={{ width: "300px", marginTop: 4 }} disabled>
            <strong>Setup New SMSF</strong> - $coming soon
          </Button>
        </Col>
      </Row>
      <CompanyTable />
    </>
  );
};

export default DashboardPage;

import { FC } from "react";
import {
  StepButtons,
  ExtendedCard,
  InputGroup,
  InputTextArea,
} from "components";
import { Col, Form, Row } from "antd";
import { DocumentsSkeleton } from "./components";
import { formatToCurrency } from "utils";
import { useMutation } from "hooks";
import { ROW_GUTTER } from "consts";
import { createUseStyles } from "react-jss";
import { hooks } from "../duck";
import { Types } from "../../duck";

const useStyles = createUseStyles({
  textAreaClassName: {
    margin: 0,
  },
});

const FIELD_NAME = "documentsDistributionID";

const Documents: FC<Types.DTStepProps> = ({
  discretionaryTrust,
  queryKey,
  cognitoID,
  onNextClick,
  ...props
}) => {
  const [form] = Form.useForm();
  const classes = useStyles();
  const { isLoading, trustDocumentsDistribution } =
    hooks.useTrustDocumentsDistribution();

  const { isPending, mutate: updateDT5 } = useMutation<
    any,
    { documentID: number; postalAddress: string }
  >({
    apiName: "client",
    path: `/${cognitoID}/discretionary-trust/${discretionaryTrust.id}/step-${
      props.currentStep + 1
    }`,
    method: "put",
    invalidateQueries: [queryKey],
    onSuccess: () => {
      onNextClick?.();
    },
  });

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={discretionaryTrust}
      onFinish={(values) => {
        if (values[FIELD_NAME] !== discretionaryTrust[FIELD_NAME]) {
          updateDT5({
            documentID: values[FIELD_NAME],
            postalAddress: values.postalAddress,
          });
        } else {
          onNextClick?.();
        }
      }}
    >
      <InputGroup
        name={FIELD_NAME}
        options={trustDocumentsDistribution.map((item) => ({
          translate: false,
          label: item.type,
          value: item.id,
        }))}
      />
      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={24} md={18} xl={18}>
          <Row justify="space-between">
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue, setFieldValue }) => {
                if (isLoading) {
                  return <DocumentsSkeleton />;
                }

                const id = getFieldValue(FIELD_NAME);

                return trustDocumentsDistribution.map((item) => (
                  <ExtendedCard
                    key={item.id}
                    title={item.type as string}
                    selected={item.id === id}
                    hoverable={item.id !== id}
                    onClick={() => {
                      setFieldValue(FIELD_NAME, item.id);
                    }}
                    description={
                      <>
                        {item.description as string}{" "}
                        <strong>{formatToCurrency(item.price || 0)}</strong>
                      </>
                    }
                  />
                ));
              }}
            </Form.Item>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={4} xl={4}>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const value = getFieldValue(FIELD_NAME);
              const record = trustDocumentsDistribution.find(
                (item) => item.id === value,
              );

              if (!record?.price) {
                return null;
              }

              return (
                <InputTextArea
                  required
                  autoFocus
                  name="postalAddress"
                  label="common.postalAddress"
                  placeholder="Postal address"
                  formItemClassName={classes.textAreaClassName}
                />
              );
            }}
          </Form.Item>
        </Col>
      </Row>

      <StepButtons {...props} loading={isPending} />
    </Form>
  );
};

export default Documents;

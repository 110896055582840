export const ENDPOINTS = {
  account: "account",
  address: "address",
  discretionaryTrust: "discretionary-trust",
  document: "document",
  client: "client",
  clients: "clients",
  companies: "companies",
  system: "system",
} as const;

import { useSelector } from "hooks";
import { ExtendedDescriptions } from "components";
import { Client } from "types";

const Billing = () => {
  const client = useSelector((state) => state.auth.client as Client);

  return (
    <ExtendedDescriptions
      items={[
        {
          label: "common.name",
          value: `${client.firstName} ${client.lastName}`,
        },
        {
          label: "common.email",
          value: client.email,
        },
      ]}
    />
  );
};

export default Billing;

import { FC } from "react";
import {
  Drawer,
  Form,
  Button,
  AsyncSelect,
  IndividualSelect,
} from "components";
import { useMutation } from "hooks";
import { getQueryKey } from "utils";
import { FormattedMessage } from "react-intl";
import { OfficeholderType } from "types";
import { selectors } from "../../../../duck";
import { Typography } from "antd";

interface AddNewDrawerProps {
  companyID: number;
  cognitoID: string;
  onClose: () => void;
  open: boolean;
  queryKey: string[];
  officeholders: { typeID: number }[];
}

const AddNewDrawer: FC<AddNewDrawerProps> = ({
  open,
  companyID,
  cognitoID,
  onClose,
  queryKey,
  officeholders,
}) => {
  const [form] = Form.useForm();
  const { isPending, mutate } = useMutation({
    method: "post",
    apiName: "client",
    invalidateQueries: [queryKey],
    path: selectors.getUrlPath({
      cognitoID,
      companyID,
      path: "officeholders",
    }),
    onSuccess: () => {
      onClose();
      form.resetFields();
    },
  });

  return (
    <Drawer
      open={open}
      destroyOnClose
      onClose={onClose}
      title={<FormattedMessage id="company.thirdStep.addOfficeholder" />}
      footer={
        <Button
          type="primary"
          loading={isPending}
          onClick={() => form.submit()}
        >
          Submit
        </Button>
      }
    >
      <Form form={form} layout="vertical" onFinish={mutate} preserve={false}>
        <Typography.Title level={5}>Select Individual</Typography.Title>
        <Typography.Paragraph>
          Select an existing individual from the list below, or press
          &quot;+&quot; to add a new individual.
        </Typography.Paragraph>
        <IndividualSelect
          parentForm={form}
          drawerRequiredFields={{
            firstName: true,
            lastName: true,
            addressID: true,
            dateOfBirth: true,
            placeOfBirthCity: true,
            placeOfBirthCountryID: true,
          }}
        />

        <Typography.Title level={5}>Select Office</Typography.Title>
        <Typography.Paragraph>
          Then select the office that the individual will hold.
          <br />
          Remember that a company must have at least one Director and exactly
          one Public Officeholder. The position of Secretary is optional.
        </Typography.Paragraph>
        <AsyncSelect<{ id: number; description: string }>
          required
          label="common.type"
          name="typeID"
          queryKey={getQueryKey("officeholderTypes")}
          apiName="system"
          path="/officeholder-types"
          rules={[
            {
              validator(rule, value) {
                if (
                  value === OfficeholderType.PublicOfficer &&
                  officeholders.some(
                    (item) => item.typeID === OfficeholderType.PublicOfficer,
                  )
                ) {
                  return Promise.reject(
                    "Unable to add more than 1 public officer",
                  );
                }

                if (
                  value === OfficeholderType.Secretary &&
                  officeholders.some(
                    (item) => item.typeID === OfficeholderType.Secretary,
                  )
                ) {
                  return Promise.reject("Unable to add more than 1 secretary");
                }

                return Promise.resolve();
              },
            },
          ]}
          getOptionProps={(opt) => ({
            value: opt.id,
            children: opt.description,
          })}
        />
      </Form>
    </Drawer>
  );
};

export default AddNewDrawer;

import {
  Row,
  Col,
  Form,
  PhoneInput,
  PreLoader,
  SubmitButton,
  AddressSelect,
} from "components";
import { useSelector, useDispatch, useChildAPI } from "hooks";
import { ROW_GUTTER, COMMON_COL_PROPS } from "consts";
import { authAsyncActions } from "configs";
import { Phone } from "types";

const Contact = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { clientID, currentAddressID } = useSelector((state) => ({
    clientID: state.auth.client?.id as number,
    currentAddressID: state.auth.client?.currentAddressID,
  }));
  const { data, fetching, save, saving } = useChildAPI<Phone>({
    childRoute: "phone",
    parentID: clientID,
    parentRoute: "client",
  });

  if (fetching) {
    return <PreLoader size="default" />;
  }

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        currentAddressID,
        ...(data || {}),
      }}
      onFinish={({ currentAddressID: addressID, ...values }) => {
        save(values);
        dispatch(
          authAsyncActions.updateClient({
            id: clientID,
            currentAddressID: addressID,
          }),
        );
      }}
    >
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <PhoneInput label="phone.home" name="home" />
          <PhoneInput label="phone.work" name="work" />
          <PhoneInput label="phone.mobile" name="mobile" required />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <AddressSelect name="currentAddressID" parentForm={form} />
        </Col>
      </Row>
      <SubmitButton loading={saving} />
    </Form>
  );
};

export default Contact;

import { FC } from "react";
import {
  StepButtons,
  IndividualSelect,
  CompanySelect,
  AsyncSelect,
} from "components";
import { Form, Row, Col } from "antd";
import { ROW_GUTTER, COMMON_COL_PROPS } from "consts";
import { getQueryKey } from "utils";
import { useMutation, useForm, useCompanies, useIndividuals } from "hooks";
import { TrusteeType } from "types";
import { Types } from "../../duck";

const Trustee: FC<Types.DTStepProps> = ({
  discretionaryTrust,
  onNextClick,
  queryKey,
  cognitoID,
  ...props
}) => {
  const [form] = useForm();
  const { isPending, mutate: updateDT2 } = useMutation({
    apiName: "client",
    path: `/${cognitoID}/discretionary-trust/${discretionaryTrust.id}/step-${
      props.currentStep + 1
    }`,
    method: "patch",
    invalidateQueries: [queryKey],
    onSuccess: () => {
      onNextClick?.();
    },
  });

  const { companies = [], companiesLoading } = useCompanies({
    registeredOnly: true,
  });
  const { individuals = [], individualsLoading } = useIndividuals();

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={discretionaryTrust}
      onFinish={(values) => {
        if (form.isFieldsTouched()) {
          updateDT2(values);
        } else {
          onNextClick?.();
        }
      }}
    >
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <AsyncSelect<TrusteeType>
            required
            label="common.type"
            name="trusteeTypeID"
            queryKey={getQueryKey("trusteeTypes")}
            apiName="system"
            path="/trustee-types"
            getOptionProps={(opt) => ({
              value: opt.id,
              children: opt.type,
            })}
          />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <Form.Item noStyle dependencies={["trusteeTypeID"]}>
            {({ getFieldValue }) => {
              const value = getFieldValue("trusteeTypeID");

              if (value === 1) {
                return (
                  <IndividualSelect
                    parentForm={form}
                    fetchOptions={{
                      options: individuals,
                      loading: individualsLoading,
                    }}
                    drawerRequiredFields={{
                      firstName: true,
                      lastName: true,
                      addressID: true,
                    }}
                  />
                );
              }

              return (
                <CompanySelect
                  parentForm={form}
                  fetchOptions={{
                    options: companies,
                    loading: companiesLoading,
                  }}
                  drawerRequiredFields={{
                    name: true,
                    acn: true,
                    typeID: true,
                  }}
                />
              );
            }}
          </Form.Item>
        </Col>
      </Row>
      <StepButtons {...props} loading={isPending} />
    </Form>
  );
};

export default Trustee;

import { FC } from "react";
import {
  ExtendedDescriptions,
  List,
  Row,
  Col,
  FormattedMessage,
  Typography,
  CompletePayment,
  StepButtons,
} from "components";
import {
  OfficeholdersTable,
  ShareholdersTable,
  ShareCapitalTable,
} from "../shared";
import {
  useCompanyHolders,
  useCompanyShareCapital,
  useQuery,
  useStripeScript,
} from "hooks";
import { Elements } from "@stripe/react-stripe-js";
import { ROW_GUTTER } from "consts";
import { getQueryKey, formatToCurrency } from "utils";
import { Types, hooks } from "../../duck";
import { QueryKey } from "types";

const TABLE_COL_PROPS = {
  xl: 12,
  lg: 12,
  md: 24,
  sm: 24,
  xs: 24,
};

interface ConfirmProps extends Types.StepProps {
  companyQueryKey: QueryKey;
  cognitoID: string;
}

const Confirm: FC<ConfirmProps> = ({
  company,
  companyQueryKey,
  cognitoID,
  ...props
}) => {
  const {
    ppbItems,
    ppbLoading,
    registeredOfficeItems,
    registeredOfficeLoading,
  } = hooks.useCompanyAddresses(company);
  const { data: officeholders, isLoading: officeholdersLoading } =
    useCompanyHolders({
      companyID: company.id,
      cognitoID,
      subRoute: "officeholders",
    });
  const { data: shareholders, isLoading: shareholdersLoading } =
    useCompanyHolders({
      companyID: company.id,
      cognitoID,
      subRoute: "shareholders",
    });
  const { data: shareCapital, isLoading: shareCapitalLoading } =
    useCompanyShareCapital({
      cognitoID,
      companyID: company.id,
    });
  const { data: documentDistributions } = hooks.useDocumentDistribution();

  const { data: paymentData } = useQuery<{
    client_secret: string;
    total: number;
  }>({
    staleTime: 0,
    apiName: "client",
    path: `/${cognitoID}/companies/${company.id}/payment`,
    queryKey: getQueryKey("companyPayment", company.id),
  });

  const stripeScript = useStripeScript();

  const documentDistribution = documentDistributions.find(
    (item) => item.id === company.distributionID,
  );

  return (
    <>
      {paymentData?.client_secret && (
        <Elements
          stripe={stripeScript}
          options={{
            clientSecret: paymentData.client_secret,
          }}
        >
          <CompletePayment total={paymentData.total}>
            {({ loading, disabled }) => (
              <StepButtons
                {...props}
                loading={loading}
                nextDisabled={disabled}
              />
            )}
          </CompletePayment>
        </Elements>
      )}
      <List
        size="small"
        header={<strong>Price</strong>}
        bordered
        dataSource={[
          {
            label: "Company Incorporation",
            price: 647,
          },
          ...(!documentDistribution
            ? []
            : [
                {
                  label: `Document distribution (${documentDistribution.type})`,
                  price: documentDistribution.price,
                },
              ]),
          ...(!company.registeredAgentPlan
            ? []
            : [
                {
                  label: "Registered agent plan (12mth)",
                  price: 66,
                },
              ]),
          ...(!company.domainName
            ? []
            : [
                {
                  label: "Web domain",
                  price: 0,
                },
              ]),
        ]}
        renderItem={(item) => (
          <List.Item>
            {item.label}: <strong>{formatToCurrency(item.price)}</strong>
          </List.Item>
        )}
      />

      <Row gutter={ROW_GUTTER}>
        <Col {...TABLE_COL_PROPS}>
          <Typography.Title level={5}>
            <FormattedMessage id="company.secondStep.registeredOffice" />
          </Typography.Title>
          <ExtendedDescriptions
            items={registeredOfficeItems}
            loading={registeredOfficeLoading}
          />
        </Col>
        <Col {...TABLE_COL_PROPS}>
          <Typography.Title level={5}>
            <FormattedMessage id="company.secondStep.principalPlaceOfBusiness" />
          </Typography.Title>
          <ExtendedDescriptions items={ppbItems} loading={ppbLoading} />
        </Col>
      </Row>

      <Row gutter={ROW_GUTTER}>
        <Col {...TABLE_COL_PROPS}>
          <OfficeholdersTable
            data={officeholders}
            isLoading={officeholdersLoading}
            showTitle
          />
        </Col>
        <Col {...TABLE_COL_PROPS}>
          <ShareholdersTable
            data={shareholders}
            isLoading={shareholdersLoading}
            showTitle
          />
          <ShareCapitalTable
            data={shareCapital}
            isLoading={shareCapitalLoading}
            showTitle
          />
        </Col>
      </Row>
    </>
  );
};

export default Confirm;

import { FC, useState } from "react";
import { Types } from "../../duck";
import {
  StepButtons,
  FormattedMessage,
  Button,
  Row,
  Space,
  ConfigProvider,
} from "components";
import { AddNewDrawer } from "./components";
import { COLORS } from "consts";
import { RemoveIcon, ShareCapitalTable, StepInstructions } from "../shared";
import { useCompanyShareCapital, useDeleteMutation } from "hooks";
import { ShareCapital, ShareClassEnum } from "types";
import { getQueryKey } from "utils";

interface ShareCapitalStepProps extends Types.StepProps {
  cognitoID: string;
}

const ShareCapitalStep: FC<ShareCapitalStepProps> = ({
  company,
  cognitoID,
  ...props
}) => {
  const [drawerOpen, toggleDrawer] = useState(false);
  const { data, isLoading, queryKey } = useCompanyShareCapital({
    cognitoID,
    companyID: company.id,
  });

  const invalidateQueries = [queryKey, getQueryKey("shareholders", company.id)];

  const { mutate, isPending } = useDeleteMutation<ShareCapital[]>({
    apiName: "client",
    path: `/${cognitoID}/companies/${company.id}/share-capital-standard`,
    invalidateQueries,
  });

  const standardAdded = data.some(
    (item) => item.shareClassID === ShareClassEnum.ORD,
  );

  return (
    <>
      <StepInstructions
        currentStep={props.currentStep}
        title="common.shareCapital"
        description="company.fourthStep.description"
        descriptionValues={{
          title: <FormattedMessage id="common.shareCapital" />,
        }}
      />
      <AddNewDrawer
        cognitoID={cognitoID}
        open={drawerOpen}
        shareCapital={data}
        companyID={company.id}
        onClose={() => {
          toggleDrawer(false);
        }}
      />
      <Row justify="end" style={{ marginBottom: 14 }}>
        <Space>
          <Button
            type="primary"
            onClick={() => {
              toggleDrawer(true);
            }}
          >
            Add share type
          </Button>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: COLORS.purple[5],
              },
              components: {
                Button: {
                  algorithm: true,
                },
              },
            }}
          >
            {!standardAdded && !isLoading && (
              <Button
                type="primary"
                loading={isPending}
                onClick={() => {
                  mutate();
                }}
              >
                Use standard
              </Button>
            )}
          </ConfigProvider>
        </Space>
      </Row>
      <ShareCapitalTable
        data={data}
        isLoading={isLoading}
        actionsColumn={[
          {
            title: "common.actions",
            dataIndex: "id",
            render: ({ record }: { record: ShareCapital }) => (
              <RemoveIcon
                cognitoID={cognitoID}
                companyID={company.id}
                itemID={record.id}
                subRoute="share-capital"
                queryKeys={invalidateQueries}
              />
            ),
          },
        ]}
      />

      <StepButtons {...props} nextDisabled={!data.length} />
    </>
  );
};

export default ShareCapitalStep;

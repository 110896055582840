import { FC } from "react";
import { ExtendedTable } from "components";
import { ViewFile } from "./components";
import { useQuery } from "hooks";
import { getQueryKey } from "utils";
import { selectors } from "../../../../duck";
import { Document } from "types";

interface DocumentsProps {
  companyID: number;
  cognitoID: string;
}

const Documents: FC<DocumentsProps> = ({ companyID, cognitoID }) => {
  const queryKey = getQueryKey("companyDocuments", companyID);
  const { data: documents, isLoading } = useQuery<Document[]>({
    apiName: "client",
    queryKey,
    path: selectors.getUrlPath({
      cognitoID,
      companyID,
      path: "documents",
    }),
  });

  return (
    <ExtendedTable
      loading={isLoading}
      dataSource={documents}
      columns={[
        {
          title: "common.id",
          dataIndex: "id",
        },
        {
          title: "common.description",
          dataIndex: "description",
        },
        {
          title: "common.file",
          dataIndex: "fileID",
          render: ({ value }) => <ViewFile fileID={value} />,
        },
      ]}
    />
  );
};

export default Documents;

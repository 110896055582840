import { useSelector } from "hooks";
import { Typography, Divider, Row, Col, Image } from "components";
import { ROW_GUTTER, COMMON_COL_PROPS } from "consts";
import { dashboardLogo } from "assets";
import { Account } from "types";

const CompanyAbout = () => {
  const { name, email, telephone, address } = useSelector(
    (state) => state.auth.account as Account,
  );

  return (
    <Row gutter={ROW_GUTTER}>
      <Col {...COMMON_COL_PROPS}>
        <Image preview={false} src={dashboardLogo} />
      </Col>
      <Col {...COMMON_COL_PROPS}>
        <Typography.Title level={3}>{name}</Typography.Title>
        <Divider />
        <Typography.Paragraph>
          Suite 592, 585 {address?.streetName} {address?.streetType}
          <br />
          {address?.suburb} {address?.postcode} {address?.state}
          <br />
          {address?.country}
        </Typography.Paragraph>
        <Typography.Text>
          Email: &nbsp;
          <Typography.Link href={`mailto:${email}`}>{email}</Typography.Link>
        </Typography.Text>
        <Typography.Paragraph>Telephone: {telephone}</Typography.Paragraph>
      </Col>
    </Row>
  );
};

export default CompanyAbout;

import { FC } from "react";
import {
  StepButtons,
  Form,
  Row,
  Col,
  PreLoader,
  IndividualSelect,
  Alert,
} from "components";
import { ROW_GUTTER, COMMON_COL_PROPS } from "consts";
import { getQueryKey } from "utils";
import { useMutation, useQuery, useForm, useIndividuals } from "hooks";
import { Types } from "../../duck";

const RelevantPersons: FC<Types.DTStepProps> = ({
  discretionaryTrust,
  onNextClick,
  queryKey,
  cognitoID,
  ...props
}) => {
  const [form] = useForm();
  const urlPath = `/${cognitoID}/discretionary-trust/${discretionaryTrust.id}`;
  const { isPending, mutate: updateDT3 } = useMutation({
    apiName: "client",
    path: `${urlPath}/step-${props.currentStep + 1}`,
    method: "patch",
    invalidateQueries: [queryKey],
    onSuccess: () => {
      onNextClick?.();
    },
  });

  const { individuals = [], individualsLoading } = useIndividuals();

  const {
    isLoading,
    isRefetching,
    data: appointorIDs = [],
  } = useQuery<{ id: [] }>({
    apiName: "client",
    path: `${urlPath}/appointors`,
    queryKey: getQueryKey("appointors", cognitoID, discretionaryTrust.id),
  });

  if (isLoading || isRefetching) {
    return <PreLoader />;
  }

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        ...discretionaryTrust,
        appointorIDs,
      }}
      onFinish={(values) => {
        if (form.isFieldsTouched()) {
          updateDT3(values);
        } else {
          onNextClick?.();
        }
      }}
    >
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <IndividualSelect
            name="settlorID"
            label="discretionaryTrust.settlor"
            parentForm={form}
            fetchOptions={{
              options: individuals,
              loading: individualsLoading,
            }}
            drawerRequiredFields={{
              firstName: true,
              lastName: true,
              addressID: true,
            }}
          />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <IndividualSelect
            multi
            name="appointorIDs"
            label="discretionaryTrust.appointor"
            parentForm={form}
            maxCount={2}
            fetchOptions={{
              options: individuals,
              loading: individualsLoading,
            }}
            drawerRequiredFields={{
              firstName: true,
              lastName: true,
              addressID: true,
              dateOfBirth: true,
            }}
          />
          <Form.Item noStyle dependencies={["appointorIDs"]}>
            {({ getFieldValue }) => {
              const ids = getFieldValue("appointorIDs");

              return (
                <Alert
                  type={ids.length ? "success" : "error"}
                  message="1 or 2 appointors."
                  style={{ marginBottom: 14 }}
                />
              );
            }}
          </Form.Item>
        </Col>
      </Row>
      <StepButtons {...props} loading={isPending} />
    </Form>
  );
};

export default RelevantPersons;

import { FC, useState } from "react";
import { Form, FormItemProps } from "antd";
import PhoneInput from "react-phone-input-2";
import { useIntl } from "hooks";

const phoneProps = {
  inputStyle: { width: "100%" },
  enableSearch: true,
};

interface PhoneInputProps {
  value?: string | null;
  onChange?: (v: any) => void;
}

const CustomPhoneInput: FC<PhoneInputProps> = ({ value, onChange }) => {
  const [defaultCountryCode, setCountryCode] = useState(
    window.navigator.language.split("-")[1]?.toLowerCase() || "",
  );

  const onInputChange = (
    newValue: string,
    countryData: { countryCode: string },
  ) => {
    if (onChange) {
      onChange(newValue);
    }

    if (countryData.countryCode !== defaultCountryCode) {
      setCountryCode(countryData.countryCode);
    }
  };

  const country = !value ? { country: defaultCountryCode } : {};
  const preferredCountries = ["au", "us", "sg", "cn", "tw"];
  // language === "zh"
  //   ? ["cn", "tw", "sg", "us", "au"] :["au", "us", "sg", "cn", "tw"];

  return (
    <PhoneInput
      {...country}
      {...phoneProps}
      value={value}
      onChange={onInputChange}
      enableSearch
      preferredCountries={preferredCountries}
      preserveOrder={["preferredCountries"]}
      masks={{ au: "(.) ....-...." }}
    />
  );
};

export default ({
  required,
  rules = [],
  label,
  ...props
}: FormItemProps & { label: string }) => {
  const intl = useIntl();

  return (
    <Form.Item
      {...props}
      label={intl.formatMessage({ id: label, defaultMessage: "" })}
      getValueProps={(value) => value && { value }}
      rules={[
        {
          required,
          message: intl.formatMessage({ id: "validation.required" }),
        },
        ...rules,
      ]}
    >
      <CustomPhoneInput />
    </Form.Item>
  );
};

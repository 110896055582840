import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { Auth, API } from "utils";
import { notification } from "antd";
import { LoginPayload } from "./types";
import { Client } from "types";

export const loginStart = createAction<LoginPayload>("auth/loginStart");

export const logOut = createAsyncThunk("auth/logOut", () => Auth.signOut());

export const loginError = createAction<{ confirmSignup: boolean } | undefined>(
  "auth/loginError",
);

export const updateClient = createAsyncThunk<
  Client,
  { id: number } & Partial<Client>
>("auth/updateClient", ({ id, ...values }) => {
  return API.patch({
    apiName: "client",
    path: `/${id}`,
    options: {
      body: values,
    },
  }).catch((e: any) => {
    notification.error({
      message: e.message,
    });

    return Promise.reject(e);
  });
});

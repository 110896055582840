import { useQuery } from "hooks";
import { TrustDocumentDistribution } from "types";
import { getQueryKey } from "utils";

export const useTrustDocumentsDistribution = () => {
  const { isLoading, data = [] } = useQuery<TrustDocumentDistribution[]>({
    apiName: "discretionaryTrust",
    path: "/documents-distribution",
    queryKey: getQueryKey("trustDocumentsDistribution"),
  });

  return {
    isLoading,
    trustDocumentsDistribution: data,
  };
};

import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store, configureAmplify } from "configs";
import App from "./App";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

import { create as createJss } from "jss";
import { JssProvider } from "react-jss";
import vendorPrefixer from "jss-plugin-vendor-prefixer";
import preset from "jss-preset-default";

import "./index.css";

const jss = createJss();

jss.setup(preset());
jss.use(vendorPrefixer());

dayjs.extend(utc);
dayjs.extend(isBetween);

configureAmplify();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <Provider store={store}>
    <JssProvider jss={jss}>
      <App />
    </JssProvider>
  </Provider>,
);

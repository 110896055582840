import * as React from "react";
import { Menu, Layout, NavLink, Icons } from "components";
import * as H from "hooks";
import { routes } from "configs";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  sider: {
    "& .ant-layout-sider-trigger": {
      position: "static",
      borderInlineEnd: "1px solid rgba(5, 5, 5, 0.06)",
    },
  },
  menu: {
    overflow: "auto",
    height: "100%",
  },
  itemGroup: {
    textAlign: "center",
  },
});

const MenuComponent = () => {
  const classes = useStyles();
  const [state, setState] = React.useState<{
    selectedKeys: string[];
    openKeys: string[];
    collapsed: boolean;
  }>({
    selectedKeys: ["dashboard"],
    openKeys: [],
    collapsed: !!localStorage.getItem("collapsedMenu"),
  });
  const { pathname } = H.useLocation();

  React.useEffect(() => {
    const openKeys = [pathname.slice(0, pathname.lastIndexOf("/"))];

    setState((prevState) => ({
      ...prevState,
      openKeys: state.collapsed ? prevState.openKeys : openKeys,
      selectedKeys: [pathname],
    }));
  }, [pathname, state.collapsed]);

  const onCollapse = (collapsed: boolean) => {
    if (collapsed) {
      localStorage.setItem("collapsedMenu", "true");
    } else {
      localStorage.removeItem("collapsedMenu");
    }
    setState((prev) => ({ ...prev, collapsed }));
  };
  const onMenuOpenChange = (openKeys: string[]) => {
    setState((prev) => ({
      ...prev,
      openKeys: [openKeys[openKeys.length - 1]],
    }));
  };

  return (
    <Layout.Sider
      collapsible
      collapsed={state.collapsed}
      onCollapse={onCollapse}
      theme="light"
      className={classes.sider}
    >
      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={[routes.dashboard]}
        className={classes.menu}
        selectedKeys={state.selectedKeys}
        openKeys={state.openKeys}
        onOpenChange={onMenuOpenChange}
        items={[
          {
            key: routes.dashboard,
            title: "Dashboard",
            icon: <Icons.DashboardOutlined />,
            label: <NavLink to="/">Dashboard</NavLink>,
          },
          {
            key: routes.about,
            title: "About",
            icon: <Icons.CommentOutlined />,
            label: <NavLink to={routes.about}>About</NavLink>,
          },
          {
            key: routes.discretionaryTrust.main,
            title: "Discretionary Trust",
            label: "Discretionary Trust",
            icon: <Icons.FolderOpenOutlined />,
            children: [
              {
                key: routes.discretionaryTrust.list(),
                title: "List",
                icon: <Icons.BarsOutlined />,
                label: (
                  <NavLink to={routes.discretionaryTrust.list()}>List</NavLink>
                ),
              },
              {
                key: routes.discretionaryTrust.create(),
                title: "Create",
                icon: <Icons.PlusCircleOutlined />,
                label: (
                  <NavLink to={routes.discretionaryTrust.create()}>
                    Create
                  </NavLink>
                ),
              },
            ],
          },
          {
            key: routes.company.main,
            icon: <Icons.BuildOutlined />,
            title: "Companies",
            label: "Companies",
            children: [
              {
                key: routes.company.about(),
                title: "About",
                label: <NavLink to={routes.company.about()}>About</NavLink>,
                icon: <Icons.CommentOutlined />,
              },
              {
                key: routes.company.list(),
                title: "My Companies",
                label: (
                  <NavLink to={routes.company.list()}>My Companies</NavLink>
                ),
                icon: <Icons.BarsOutlined />,
              },
              {
                key: routes.company.create(),
                title: "Create Company",
                label: (
                  <NavLink to={routes.company.create()}>New Company</NavLink>
                ),
                icon: <Icons.PlusCircleOutlined />,
              },
            ],
          },
          {
            key: routes.settings.main,
            icon: <Icons.SettingOutlined />,
            title: "Settings",
            label: "Settings",
            children: [
              {
                key: routes.settings.userProfile(),
                title: "User Profile",
                label: (
                  <NavLink to={routes.settings.userProfile()}>
                    User Profile
                  </NavLink>
                ),
                icon: <Icons.UserOutlined />,
              },
            ],
          },
        ]}
      />
    </Layout.Sider>
  );
};

export default MenuComponent;

import { FC } from "react";
import { ExtendedTable, FormattedMessage, Typography } from "components";
import { formatToCurrency } from "utils";
import { ShareCapital } from "types";
import { Types } from "../../../duck";

const OfficeholderTable: FC<Types.CommonTableProps<ShareCapital>> = ({
  data,
  isLoading,
  showTitle,
  actionsColumn = [],
}) => {
  return (
    <>
      {showTitle && (
        <Typography.Title level={5}>
          <FormattedMessage id="common.shareCapital" />
        </Typography.Title>
      )}
      <ExtendedTable<ShareCapital>
        loading={isLoading}
        dataSource={data}
        columns={[
          {
            title: "common.code",
            dataIndex: "shareCode",
          },
          {
            title: "company.shareClass",
            dataIndex: "shareClass",
          },
          {
            title: "common.quantity",
            dataIndex: "quantity",
          },
          {
            title: "common.price",
            dataIndex: "price",
          },
          {
            title: "common.total",
            dataIndex: "price",
            render: ({ record }) =>
              formatToCurrency(record.quantity * record.price),
          },
          ...actionsColumn,
        ]}
      />
    </>
  );
};

export default OfficeholderTable;

import { FC } from "react";
import {
  StepButtons,
  ExtendedCard,
  InputTextArea,
  InputGroup,
  Form,
  Row,
  Col,
} from "components";
import { DocumentsSkeleton } from "./components";
import { StepInstructions } from "../shared";
import { ROW_GUTTER } from "consts";
import { hooks, Types } from "../../duck";
import { formatToCurrency } from "utils";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  textAreaClassName: {
    margin: 0,
  },
});

const Documents: FC<Types.StepProps> = ({ company, onNextClick, ...props }) => {
  const [form] = Form.useForm();
  const classes = useStyles();
  const { data, isLoading } = hooks.useDocumentDistribution();

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={company}
      onFinish={(values) => {
        onNextClick(values, values.distributionID !== company.distributionID);
      }}
    >
      <StepInstructions
        currentStep={props.currentStep}
        title="company.sixStep.title"
        description="company.sixStep.description"
      />

      <InputGroup
        name="distributionID"
        options={data.map((item) => ({
          translate: false,
          label: item.type,
          value: item.id,
        }))}
      />
      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={24} md={18} xl={18}>
          <Row justify="space-between">
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue, setFieldValue }) => {
                if (isLoading) {
                  return <DocumentsSkeleton />;
                }

                const id = getFieldValue("distributionID");

                return data.map((item) => (
                  <ExtendedCard
                    key={item.id}
                    title={item.type as string}
                    selected={item.id === id}
                    hoverable={item.id !== id}
                    onClick={() => {
                      setFieldValue("distributionID", item.id);
                    }}
                    description={
                      <>
                        {item.description as string}{" "}
                        <strong>{formatToCurrency(item.price || 0)}</strong>
                      </>
                    }
                  />
                ));
              }}
            </Form.Item>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={4} xl={4}>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const value = getFieldValue("distributionID");
              const record = data.find((item) => item.id === value);

              if (!record?.price) {
                return null;
              }

              return (
                <InputTextArea
                  required
                  autoFocus
                  name="postalAddress"
                  label="common.postalAddress"
                  placeholder="Postal address"
                  formItemClassName={classes.textAreaClassName}
                />
              );
            }}
          </Form.Item>
        </Col>
      </Row>

      <StepButtons {...props} />
    </Form>
  );
};

export default Documents;

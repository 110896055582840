export const STEP_TITLES = [
  "common.company",
  "common.address",
  "common.officeholders",
  "common.shareCapital",
  "common.shareholders",
  "common.documents",
  "company.seventhStep.title",
  "common.confirm",
];

import InputSelect from "../basic";
import { InputSelectProps } from "../types";
import { useQuery } from "hooks";
import { ApiNames } from "types";

interface AsyncSelectProps<QueryFnData, SelectData = QueryFnData>
  extends Omit<InputSelectProps<QueryFnData>, "options" | "loading"> {
  apiName: ApiNames;
  queryKey: string[];
  path?: string;
  enabled?: boolean;
  select?: (data: QueryFnData[]) => SelectData[];
}

const AsyncSelect = <QueryFnData, Data extends QueryFnData = QueryFnData>({
  apiName,
  path,
  queryKey,
  select,
  enabled,
  ...props
}: AsyncSelectProps<QueryFnData, Data>) => {
  const { data = [], isLoading } = useQuery<QueryFnData[], Data[]>({
    queryKey,
    apiName,
    path,
    select,
    enabled,
  });

  return <InputSelect {...props} initialLoading={isLoading} options={data} />;
};

export default AsyncSelect;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Types } from "./duck";
import { BreakpointMap } from "types";

const initialState: Types.State = {
  loading: false,

  breakpoint: {
    mobile: false,
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false,
  },
};

const appState = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setBreakpoints: (state, action: PayloadAction<Partial<BreakpointMap>>) => {
      state.breakpoint = {
        ...state.breakpoint,
        ...action.payload,
        mobile:
          (action.payload.sm && !action.payload.md) || !!action.payload.xs,
      };
    },
  },
  extraReducers: () => {},
});

export const { reducer, actions } = appState;

import { FC } from "react";
import {
  StepButtons,
  InputText,
  InputDatePicker,
  InputSelect,
} from "components";
import { Form, Row, Col } from "antd";
import { FormattedMessage } from "react-intl";
import { ROW_GUTTER, COMMON_COL_PROPS } from "consts";
import { getQueryKey } from "utils";
import { useMutation, useQuery } from "hooks";
import { AustralianState } from "types";
import { Types } from "../../duck";

const Trust: FC<Types.DTStepProps> = ({
  discretionaryTrust,
  onNextClick,
  queryKey,
  cognitoID,
  ...props
}) => {
  const [form] = Form.useForm();
  const { data: states = [], isLoading: statesLoading } = useQuery<
    AustralianState[]
  >({
    apiName: "system",
    path: "/states",
    queryKey: getQueryKey("states"),
  });
  const { isPending, mutate: updateDT1 } = useMutation({
    apiName: "client",
    path: `/${cognitoID}/discretionary-trust/${discretionaryTrust.id}/step-${
      props.currentStep + 1
    }`,
    method: "patch",
    invalidateQueries: [queryKey],
    onSuccess: () => {
      onNextClick?.();
    },
  });

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={discretionaryTrust}
      onFinish={(values) => {
        if (form.isFieldsTouched()) {
          updateDT1(values);
        } else {
          onNextClick?.();
        }
      }}
    >
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <InputText required name="trustName" label="common.trustName" />
          <InputSelect
            required
            name="stateID"
            label="address.state"
            options={states}
            initialLoading={statesLoading}
            getOptionProps={(opt) => ({
              value: opt.id,
              children: opt.name,
            })}
          />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <InputDatePicker
            required
            name="deedDate"
            label="discretionaryTrust.deedDate"
          />
          <Form.Item noStyle dependencies={["stateID"]}>
            {({ getFieldValue }) => {
              const stateID = getFieldValue("stateID");

              if (!stateID) {
                return null;
              }

              const record = states.find((state) => state.id === stateID);

              if (!record) {
                return null;
              }

              return (
                <FormattedMessage
                  id={`discretionaryTrust.states.${record.abbreviation}`}
                  defaultMessage="&#8203;"
                  values={{
                    br: <br />,
                  }}
                />
              );
            }}
          </Form.Item>
        </Col>
      </Row>
      <StepButtons {...props} loading={isPending} />
    </Form>
  );
};

export default Trust;

import { useDispatch, useSelector } from "hooks";
import { authAsyncActions } from "configs";
import { createUseStyles } from "react-jss";
import {
  Row,
  Col,
  Card,
  Form,
  Image,
  InputText,
  InputEmail,
  Button,
  NavLink,
  VerifyPassword,
  Typography,
  Divider,
} from "components";
import { dashboardLogo } from "assets";

const { Paragraph } = Typography;

const useStyles = createUseStyles({
  card: { width: "50%", maxWidth: 400 },
  form: { marginTop: 20 },
  row: {
    height: "100%",
  },
  col: {
    height: "100%",
  },
  outerCard: { height: "100%", backgroundColor: "#1677ff22" },
  wrapper: {
    minHeight: 360,
    backgroundColor: "#fff",
    padding: 24,
  },
});

const COL_PROPS = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
};

const Login = () => {
  const [form] = Form.useForm();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, confirmSignup } = useSelector((state) => ({
    loading: state.auth.loading,
    confirmSignup: state.auth.confirmSignup,
  }));

  if (confirmSignup) {
    const values = form.getFieldsValue(["email", "password"]);

    return <VerifyPassword {...values} />;
  }

  const onSubmit = async ({ email, password }: any) => {
    dispatch(
      authAsyncActions.loginStart({
        email,
        password,
      }),
    );
  };

  return (
    <Row className={classes.row}>
      <Col {...COL_PROPS} className={classes.col}>
        <Card className={classes.outerCard}>
          <Typography.Title level={2}>
            Welcome to MyCorpManager.
          </Typography.Title>
          <Divider />
          <Typography.Title level={5}>
            Your premier portal for the online setup and management of your
            corporation.
          </Typography.Title>
          <Divider />
          <Paragraph>
            With our easy-to-use application, you can quickly incorporate your
            Australian company and manage their details. You can also use our
            platform shortly to setup Discretionary (Family) Trusts, Unit
            Trusts, and Self-Managed Super Funds.
          </Paragraph>
          <Divider />
          <Paragraph>Login now or Register for a new account.</Paragraph>
          <Row justify="center" align="middle" className={classes.row}>
            <Image src={dashboardLogo} preview={false} />
          </Row>
        </Card>
      </Col>
      <Col {...COL_PROPS}>
        <Row justify="center" align="middle" className={classes.row}>
          <Card title="Login to MyCorpManager" className={classes.card}>
            <Form
              form={form}
              onFinish={onSubmit}
              layout="vertical"
              className={classes.form}
            >
              <InputEmail required />
              <InputText
                required
                type="Password"
                name="password"
                label="password.password"
              />
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                Login
              </Button>
              <NavLink to="/register/pimwacorp">
                <Button style={{ width: "100%", marginTop: 4 }}>
                  Register Now
                </Button>
              </NavLink>
            </Form>
          </Card>
        </Row>
      </Col>
    </Row>
  );
};

export default Login;

import { FC } from "react";
import { ROW_GUTTER, COMMON_COL_PROPS } from "consts";
import {
  StepButtons,
  Form,
  FormattedMessage,
  Row,
  Col,
  AddressSelect,
} from "components";
import { StepInstructions } from "../shared";
import { Types } from "../../duck";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  form: {
    minHeight: 300,
  },
});

const AddressStep: FC<Types.StepProps> = ({
  onNextClick,
  company,
  ...props
}) => {
  const classes = useStyles();
  const [form] = Form.useForm();

  const registeredOffice = (
    <FormattedMessage id="company.secondStep.registeredOffice" />
  );
  const principalPlaceOfBusiness = (
    <FormattedMessage id="company.secondStep.principalPlaceOfBusiness" />
  );

  return (
    <Form
      className={classes.form}
      form={form}
      layout="vertical"
      initialValues={company}
      onFinish={async (values) => {
        onNextClick(values, form.isFieldsTouched());
      }}
    >
      <StepInstructions
        currentStep={props.currentStep}
        title="company.secondStep.title"
        description="company.secondStep.description"
        descriptionValues={{
          registeredOffice,
          principalPlaceOfBusiness,
        }}
      />
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <AddressSelect
            label="company.secondStep.registeredOffice"
            name="registeredOfficeID"
            parentForm={form}
          />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <AddressSelect
            parentForm={form}
            name="principalPlaceOfBusinessID"
            label="company.secondStep.principalPlaceOfBusiness"
          />
        </Col>
      </Row>
      <StepButtons {...props} />
    </Form>
  );
};

export default AddressStep;

import { FC, useState } from "react";
import { Typography, Icons, Button, notification } from "components";
import { Storage } from "utils";

interface ViewFileProps {
  fileID: string;
}

const ViewFile: FC<ViewFileProps> = ({ fileID }) => {
  const [state, setState] = useState<{
    fetchingUrl: boolean;
    url: string | null;
  }>({
    fetchingUrl: false,
    url: null,
  });

  if (state.url) {
    return (
      <Typography.Link href={state.url} target="_blank">
        <Icons.EyeOutlined />
      </Typography.Link>
    );
  }

  return (
    <Button
      type="link"
      loading={state.fetchingUrl}
      icon={<Icons.DownloadOutlined />}
      onClick={async () => {
        try {
          setState((prevState) => ({
            ...prevState,
            fetchingUrl: true,
          }));

          const { url } = await Storage.getUrl({ key: fileID });

          setState({
            fetchingUrl: false,
            url: url.toString(),
          });
        } catch (e: any) {
          setState((prevState) => ({
            ...prevState,
            fetchingUrl: false,
          }));

          notification.error({
            message: e.message,
          });
        }
      }}
    />
  );
};

export default ViewFile;

import * as React from "react";
import { Form, FormItemProps, Input, InputProps } from "antd";
import { useIntl } from "hooks";

interface InputEmailProps
  extends FormItemProps,
    Pick<InputProps, "disabled" | "autoComplete"> {
  required?: boolean;
  isDuplicate?: boolean;
  label?: string;
}

const InputEmail: React.FC<InputEmailProps> = ({
  required,
  disabled,
  label = "common.email",
  name = "email",
  isDuplicate,
  autoComplete,
}) => {
  const intl = useIntl();

  return (
    <Form.Item
      name={name}
      label={intl.formatMessage({ id: label, defaultMessage: "" })}
      rules={[
        {
          required,
          message: intl.formatMessage({ id: "validation.required" }),
        },
        {
          type: "email",
          message: intl.formatMessage({ id: "validation.invalidEmail" }),
        },
        {
          validator() {
            return isDuplicate
              ? Promise.reject("Email already exists")
              : Promise.resolve();
          },
        },
      ]}
    >
      <Input autoComplete={autoComplete} disabled={disabled} />
    </Form.Item>
  );
};
export default InputEmail;

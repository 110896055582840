import { Row, Col, Layout, Button, Icons } from "components";
import { Logo } from "./components";
import { createUseStyles } from "react-jss";
import { authAsyncActions } from "configs";
import { useSelector, useDispatch } from "hooks";

const useStyles = createUseStyles({
  header: {
    padding: "0 24px",
    background: "#fff",
    height: "auto",
    borderBottom: "1px solid #f0f0f0",
  },
  account: { margin: "0 10px" },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
  },
});

const Header = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authenticated, loggingOut } = useSelector(({ auth }) => ({
    authenticated: auth.client,
    loggingOut: auth.loggingOut,
  }));

  const handleLogout = () => {
    dispatch(authAsyncActions.logOut());
  };

  return (
    <Layout.Header title="Admin Portal" className={classes.header}>
      <Row justify="space-between" align="middle">
        <Col className={classes.logoWrapper}>
          <Logo />
        </Col>
        {authenticated && (
          <Col>
            <Button
              type="primary"
              loading={loggingOut}
              icon={<Icons.LogoutOutlined />}
              onClick={handleLogout}
            />
          </Col>
        )}
      </Row>
    </Layout.Header>
  );
};

export default Header;

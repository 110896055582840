import { Form, Select, Skeleton } from "antd";
import { FormattedMessage } from "react-intl";
import { createUseStyles } from "react-jss";
import { removeExSpaces } from "utils";
import _isString from "lodash/isString";
import * as Types from "../types";
import { SelectOption } from "types";

const useStyles = createUseStyles({
  skeleton: {
    width: "100% !important",
    "& .ant-skeleton-input": {
      width: "100% !important",
    },
  },
  select: {
    display: "block",
  },
});

const filterOption = (input: string, option?: SelectOption) => {
  if (typeof option?.children === "string") {
    return option?.children?.toLowerCase().indexOf(input.toLowerCase()) !== -1;
  }

  return (
    option?.children?.props?.children
      ?.toLowerCase()
      .indexOf(input.toLowerCase()) !== -1
  );
};

const Dropdown = <TOption,>({
  options = [],
  placeholder = "placeholders.select",
  allowClear = false,
  getOptionProps,
  innerRef,
  filterOption: propsFilterOption,
  ...props
}: Types.InnerSelectProps<TOption>) => {
  const classes = useStyles();

  return (
    <Select<any, any>
      {...props}
      ref={innerRef}
      className={classes.select}
      placeholder={placeholder && <FormattedMessage id={placeholder} />}
      allowClear={allowClear}
      filterOption={(input, option) => {
        if (propsFilterOption) {
          return propsFilterOption(input, option);
        }

        return filterOption(input, option);
      }}
      showSearch
    >
      {options.map((option, index) => {
        const { value, children, ...rest } = getOptionProps(option, index);

        return (
          <Select.Option key={value} value={value} {...rest}>
            {_isString(children) ? removeExSpaces(children) : children}
          </Select.Option>
        );
      })}
    </Select>
  );
};

const InputSelect = <TOption,>({
  label,
  name,
  isFormItem = true,
  initialLoading,
  preserve,
  required,
  rules = [],
  innerRef,
  noStyle,
  className,
  ...props
}: Types.InputSelectProps<TOption>) => {
  const classes = useStyles();
  const S = <Dropdown {...props} innerRef={innerRef} />;

  if (!isFormItem) {
    return S;
  }

  return (
    <Form.Item
      name={name}
      label={label && <FormattedMessage id={label} />}
      preserve={preserve}
      noStyle={noStyle}
      className={className}
      rules={[
        {
          required,
          message: <FormattedMessage id="validation.required" />,
        },
        ...rules,
      ]}
    >
      {initialLoading ? (
        <Skeleton.Input active rootClassName={classes.skeleton} />
      ) : (
        S
      )}
    </Form.Item>
  );
};

export default InputSelect;

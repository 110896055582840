import { ListenerEffectAPI, PayloadAction } from "@reduxjs/toolkit";
import { Auth } from "utils";
import { notification } from "antd";
import { authAsyncActions, authActions, AuthTypes } from "../slices";
import { operations } from "./duck";

export default async (
  action: PayloadAction<{ email: string; password: string }>,
  listenerApi: ListenerEffectAPI<any, any>,
) => {
  // Can cancel other running instances
  listenerApi.cancelActiveListeners();

  try {
    const { email, password, onSuccess } =
      action.payload as AuthTypes.LoginPayload;

    const { nextStep } = await Auth.signIn({
      username: email,
      password,
    });

    if (nextStep.signInStep === "CONFIRM_SIGN_UP") {
      listenerApi.dispatch(
        authAsyncActions.loginError({
          confirmSignup: true,
        }),
      );

      return;
    }

    const { userId: cognitoID } = await Auth.getCurrentUser();

    const data = await operations.getInitialData(cognitoID);

    listenerApi.dispatch(authActions.loginSuccess(data));

    onSuccess?.();
  } catch (e: any) {
    listenerApi.dispatch(authAsyncActions.loginError());

    notification.error({
      message: e?.response?.data?.error || e.message,
    });
  }
};

import { useQuery, useMutation } from "../tanstack";
import { getQueryKey } from "utils";

interface Args {
  parentRoute: "client";
  parentID: number;
  enabled?: boolean;
  childRoute: "phone";
}

const useChildAPI = <TRecord, Variables = void>({
  parentID,
  parentRoute,
  childRoute,
  enabled,
}: Args) => {
  const queryKey = getQueryKey(childRoute, {
    parentRoute,
    parentID,
  });

  const { data, isLoading, isRefetching } = useQuery<TRecord>({
    apiName: parentRoute,
    path: `/${parentID}/${childRoute}`,
    queryKey,
    enabled,
  });

  const { mutateAsync, isPending } = useMutation<TRecord, Variables>({
    apiName: parentRoute,
    path: `/${parentID}/${childRoute}`,
    invalidateQueries: [queryKey],
    method: data && !Array.isArray(data) ? "patch" : "post",
  });

  return {
    data,
    fetching: isLoading,
    reFetching: isRefetching,
    save: mutateAsync,
    saving: isPending,
  };
};

export default useChildAPI;

import { createSlice, PayloadAction, isAnyOf } from "@reduxjs/toolkit";
import { Types, asyncActions } from "./duck";
import { InitialData } from "types";
import { loginStart } from "./duck/actions";

const initialState: Types.State = {
  authenticating: true,
  loading: false,
  loggingOut: false,
  confirmSignup: false,
  client: null,
  account: null,
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticateStart: (state) => {
      state.authenticating = true;
    },
    authenticateError: (state) => {
      state.authenticating = false;
    },
    authenticateSuccess: (state, { payload }: PayloadAction<InitialData>) => {
      state.authenticating = false;
      state.client = payload.client;
      state.account = payload.account;
    },
    loginSuccess: (state, { payload }: PayloadAction<InitialData>) => {
      state.loading = false;
      state.client = payload.client;
      state.account = payload.account;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(asyncActions.logOut.pending, (state) => {
      state.loggingOut = true;
    });

    builder.addCase(asyncActions.loginError, (state, { payload }) => {
      state.loading = false;
      state.confirmSignup = !!payload?.confirmSignup;
    });

    builder.addCase(
      asyncActions.updateClient.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.client = payload;
      },
    );

    builder.addCase(asyncActions.logOut.fulfilled, () => ({
      ...initialState,
      authenticating: false,
    }));

    const pendingMatcher = isAnyOf(
      loginStart,
      asyncActions.updateClient.pending,
    );
    const rejectMatcher = isAnyOf(asyncActions.updateClient.rejected);

    builder.addMatcher(pendingMatcher, (state) => {
      state.loading = true;
    });
    builder.addMatcher(rejectMatcher, (state) => {
      state.loading = false;
    });
  },
});

export const { reducer, actions } = auth;

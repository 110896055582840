import { FC, useCallback } from "react";
import {
  Button,
  Col,
  Divider,
  FormattedMessage,
  Image,
  Row,
  Flex,
  Form,
  InputText,
} from "components";
import { dashboardLogo } from "assets";
import { useNavigate, useMutation, useSelector, useForm } from "hooks";
import { getQueryKey } from "utils";
import { routes } from "configs";
import _debounce from "lodash/debounce";
import { TrustDiscretionary } from "types";

type Variables = Pick<TrustDiscretionary, "trustName">;

const DiscretionaryTrustCreate: FC = () => {
  const [form] = useForm();
  const navigate = useNavigate();
  const cognitoID = useSelector(
    (state) => state.auth.client?.cognitoID as string,
  );
  const { isPending: creating, mutate: createDiscretionaryTrust } = useMutation<
    TrustDiscretionary,
    Variables
  >({
    apiName: "client",
    path: `/${cognitoID}/discretionary-trust`,
    method: "post",
    invalidateQueries: [getQueryKey("discretionaryTrustList", cognitoID)],
    onSuccess: (trustDiscretionary) => {
      navigate(routes.discretionaryTrust.details(trustDiscretionary.id));
    },
  });

  const {
    data: existingItems = [],
    isPending: searching,
    mutate: search,
  } = useMutation<TrustDiscretionary[], Variables>({
    apiName: "client",
    path: `/${cognitoID}/discretionary-trust/search`,
    method: "post",
    onSuccess: (result) => {
      form.setFields([
        {
          name: "trustName",
          errors: !result.length
            ? []
            : ["A record with the given trust name already exists"],
        },
      ]);
    },
  });

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    _debounce((value) => search({ trustName: value }), 400),
    [],
  );

  const loading = creating || searching;
  const createDisabled = searching || !!existingItems.length;

  return (
    <>
      <Row>
        <Col xs={24} sm={15} md={15} lg={15}>
          A description goes here
        </Col>
        <Col xs={24} sm={9} md={9} lg={9}>
          <Image src={dashboardLogo} preview={false} />
        </Col>
      </Row>
      <Divider />
      <Form<Variables>
        layout="vertical"
        form={form}
        onValuesChange={(values) => {
          if (values.trustName) {
            debouncedSearch(values.trustName);
          }
        }}
        onFinish={({ trustName }) => {
          if (createDisabled) {
            return;
          }

          createDiscretionaryTrust({
            trustName,
          });
        }}
      >
        <Flex gap={8} align="center">
          <InputText
            required
            placeholder="Type it here"
            name="trustName"
            label="common.trustName"
            className="animate__animated animate__fadeInRight"
          />
          <Form.Item label={<span>&nbsp;</span>}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={createDisabled}
              className="animate__animated animate__fadeInLeft"
            >
              <FormattedMessage
                id={searching ? "common.justAMoment" : "button.create"}
              />
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </>
  );
};

export default DiscretionaryTrustCreate;

import { FC } from "react";
import { ShareholdersTable, ShareCapitalTable } from "../../../shared";
import { useCompanyHolders, useCompanyShareCapital } from "hooks";
import { TabProps } from "../../types";

interface ShareHoldingsProps extends TabProps {
  cognitoID: string;
}

const ShareHoldings: FC<ShareHoldingsProps> = ({ company, cognitoID }) => {
  const { data = [], isLoading } = useCompanyHolders({
    companyID: company.id,
    cognitoID,
    subRoute: "shareholders",
  });
  const { data: shareCapital, isLoading: shareCapitalLoading } =
    useCompanyShareCapital({
      companyID: company.id,
      cognitoID,
    });

  return (
    <>
      <ShareholdersTable showTitle isLoading={isLoading} data={data} />
      <ShareCapitalTable
        showTitle
        isLoading={shareCapitalLoading}
        data={shareCapital}
      />
    </>
  );
};

export default ShareHoldings;

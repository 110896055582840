import { FC, useState } from "react";
import { Types } from "../../duck";
import { StepButtons, Flex } from "components";
import * as LC from "./components";
import { StepInstructions } from "../shared";

const Options: FC<Types.StepProps> = ({ onNextClick, company, ...props }) => {
  const [state, setState] = useState<{
    registeredAgentPlan: boolean;
    domainName: boolean;
  }>({
    registeredAgentPlan: company.registeredAgentPlan,
    domainName: company.domainName,
  });

  return (
    <>
      <StepInstructions
        currentStep={props.currentStep}
        title="company.seventhStep.title"
        description="company.seventhStep.description"
      />
      <Flex gap={14} justify="space-around" wrap="wrap">
        <LC.OptionsCard
          title="Registed Agent Plan"
          description="$66.00 (12mth)"
          hoverable={!state.registeredAgentPlan}
          selected={state.registeredAgentPlan}
          onClick={() => {
            setState((prevState) => ({
              ...prevState,
              registeredAgentPlan: !state.registeredAgentPlan,
            }));
          }}
        />
        <LC.OptionsCard
          title="Domain Name"
          description="Free Bonus - 25% off at andmaweb.com"
          hoverable={!state.domainName}
          selected={state.domainName}
          onClick={() => {
            setState((prevState) => ({
              ...prevState,
              domainName: !state.domainName,
            }));
          }}
        />
      </Flex>
      <StepButtons
        {...props}
        onNextClick={() => {
          onNextClick(
            state,
            state.registeredAgentPlan !== company.registeredAgentPlan ||
              state.domainName !== company.domainName,
          );
        }}
      />
    </>
  );
};

export default Options;

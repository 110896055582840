import dayjs from "dayjs";
import { DATE_FORMAT } from "consts";
import { ExtendedTable, FormattedMessage, Typography } from "components";
import { ColumnFilterInputTypes } from "types";
import { TableProps } from "antd";
import { Types } from "../../../duck";

const OfficeholderTable = <Data,>({
  data,
  isLoading,
  showTitle,
  actionsColumn = [],
  ...rest
}: Types.CommonTableProps<Data> & TableProps<Data>) => {
  return (
    <>
      {showTitle && (
        <Typography.Title level={5}>
          <FormattedMessage id="common.officeholders" />
        </Typography.Title>
      )}
      <ExtendedTable
        {...rest}
        loading={isLoading}
        dataSource={data}
        columns={[
          {
            title: "common.individual",
            dataIndex: "individual",
          },
          {
            title: "common.office",
            dataIndex: "office",
          },
          {
            title: "common.dateOfBirth",
            dataIndex: "dateOfBirth",
            filterInputType: ColumnFilterInputTypes.DATE_RANGE,
            render: ({ value }) => dayjs(value).format(DATE_FORMAT),
          },
          ...actionsColumn,
        ]}
      />
    </>
  );
};

export default OfficeholderTable;

import { useQuery } from "./tanstack";
import { useSelector } from "./useReactRedux";
import { getCompaniesQueryKey } from "utils";
import { Company, CompanyStatus } from "types";

const useCompanies = ({
  registeredOnly,
  disabled,
}: {
  registeredOnly?: boolean;
  disabled?: boolean;
}) => {
  const cognitoID = useSelector((state) => state.auth.client?.cognitoID);
  const queryKey = getCompaniesQueryKey(cognitoID as string);
  const { data, isLoading } = useQuery<Company[]>({
    apiName: "client",
    path: `/${cognitoID}/companies`,
    enabled: !!cognitoID && !disabled,
    queryKey,
    select: (companies) => {
      if (registeredOnly) {
        return companies.filter(
          (item) => item.statusID === CompanyStatus.Registered,
        );
      }

      return companies;
    },
  });

  return {
    companies: data,
    companiesLoading: isLoading,
    companiesQueryKey: queryKey,
  };
};

export default useCompanies;

import { FC } from "react";
import { routes } from "configs";
import {
  StepButtons,
  InputText,
  AsyncSelect,
  InputGroup,
  Row,
  Col,
  Form,
} from "components";
import { Types } from "../../duck";
import { StepInstructions } from "../shared";
import { COMMON_COL_PROPS, ROW_GUTTER } from "consts";
import { CompanyType, Jurisdiction } from "types";
import { getQueryKey } from "utils";

const Company: FC<Types.StepProps> = ({ onNextClick, company, ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={company}
      onFinish={async (values) => {
        onNextClick(values, form.isFieldsTouched());
      }}
    >
      <StepInstructions
        currentStep={props.currentStep}
        title="company.firstStep.title"
        description="company.firstStep.description"
      />
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <Row>
            <Col xs={24} md={24} lg={16}>
              <InputText required name="name" label="company.newNameShort" />
            </Col>
            <Col xs={24} md={24} lg={8}>
              <AsyncSelect<CompanyType>
                required
                name="typeID"
                apiName="companies"
                label="company.shortType"
                queryKey={getQueryKey("companyTypes")}
                path={routes.company.types()}
                getOptionProps={(opt) => ({
                  value: opt.id,
                  children: opt.description,
                })}
              />
            </Col>
          </Row>
        </Col>

        <Col {...COMMON_COL_PROPS}>
          <AsyncSelect<Jurisdiction>
            required
            name="jurisdictionID"
            label="common.jurisdiction"
            apiName="system"
            path="/jurisdictions"
            queryKey={getQueryKey("jurisdictions")}
            getOptionProps={(opt) => ({
              value: opt.id,
              children: opt.name,
            })}
          />
        </Col>
      </Row>

      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <InputGroup
            optionType="default"
            name="selfManagedSuperFund"
            label="company.firstStep.selfManagedSuperFund"
          />
          <InputGroup
            optionType="default"
            name="nameReserved"
            label="company.firstStep.nameReserved"
          />
          <InputGroup
            optionType="default"
            name="nameIdentical"
            label="company.firstStep.nameIdentical"
          />
        </Col>
      </Row>
      <StepButtons {...props} />
    </Form>
  );
};

export default Company;

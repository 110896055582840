import { useState, useCallback } from "react";

import { Divider, Card, Typography } from "antd";
import { createUseStyles } from "react-jss";
import { FormattedMessage } from "react-intl";
import { DashboardHOCState } from "types";

interface DashboardHOC<P = void> {
  Component: React.FC<P>;
  title: string;
  subTitle?: string;
}

const useStyles = createUseStyles({
  header: {
    borderBottom: "1px solid rgb(235, 237, 240)",
    marginBottom: 16,
  },
  card: { "& .ant-card-body": {} },
});

export const dashboardHOC =
  <P extends DashboardHOCState>({
    Component,
    title,
    subTitle,
  }: DashboardHOC<P>) =>
  (props: any) => {
    const [topics, setTopics] = useState<DashboardHOCState>({
      title,
      subTitle,
      extra: [],
    });

    const classes = useStyles();

    const getTopics = useCallback((newValues: DashboardHOCState) => {
      setTopics((prevState) => ({
        ...prevState,
        ...newValues,
      }));
    }, []);

    return (
      <Card
        className={classes.card}
        title={<FormattedMessage id={topics.title} />}
        extra={topics.extra}
      >
        {subTitle && (
          <>
            <Typography.Title level={5}>{subTitle}</Typography.Title>
            <Divider />
          </>
        )}
        <Component {...props} getTopics={getTopics} />
      </Card>
    );
  };

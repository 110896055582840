import { FC } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const PreLoader: FC<{ size?: "large" | "small" | "default" }> = ({
  size = "default",
}) => {
  return (
    <Spin
      indicator={<LoadingOutlined />}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      size={size}
      spinning
    />
  );
};

export default PreLoader;

interface Args {
  cognitoID: string;
  companyID: number;
  path: "documents" | "officeholders" | "share-capital" | "shareholders";
  itemID?: number;
}

export const getUrlPath = ({ cognitoID, companyID, path, itemID }: Args) => {
  const subPath = itemID ? `/${itemID}` : "";

  return `/${cognitoID}/companies/${companyID}/${path}${subPath}`;
};

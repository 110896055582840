import { getQueryKey } from "../utils";
import { useQuery } from "./tanstack";
import { ShareCapital } from "../types";

const useCompanyShareCapital = ({
  companyID,
  cognitoID,
}: {
  companyID: number;
  cognitoID: string;
}) => {
  const queryKey = getQueryKey("companyShareCapital", companyID);

  const { data = [], isLoading } = useQuery<ShareCapital[]>({
    apiName: "client",
    path: `/${cognitoID}/companies/${companyID}/share-capital`,
    queryKey,
  });

  return {
    data,
    isLoading,
    queryKey,
  };
};

export default useCompanyShareCapital;

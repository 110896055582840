import { FC } from "react";
import StatusTag from "../status-tag";
import ExtendedTable from "../extended-table";
import { NavLink, useNavigate } from "react-router-dom";
import { routes } from "configs";
import { READABLE_DATE_TIME_FORMAT } from "consts";
import dayjs from "dayjs";
import { getCompaniesQueryKey } from "utils";
import { createUseStyles } from "react-jss";
import _uniqBy from "lodash/uniqBy";
import { useQuery, useSelector } from "hooks";
import { ColumnFilterInputTypes, Company } from "types";

const useStyles = createUseStyles({
  row: {
    cursor: "pointer",
  },
});

const CompanyTable: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const cognitoID = useSelector(
    (state) => state.auth.client?.cognitoID as string,
  );
  const { data, isLoading } = useQuery<Company[]>({
    apiName: "client",
    path: `/${cognitoID}/companies`,
    queryKey: getCompaniesQueryKey(cognitoID),
  });

  return (
    <ExtendedTable<Company>
      loading={isLoading}
      dataSource={data}
      onRow={(record) => ({
        className: classes.row,
        onClick: () => navigate(routes.company.view({ id: record.id })),
      })}
      columns={[
        {
          title: "company.companyTable.name",
          dataIndex: "name",
          render: ({ value, record }) => (
            <NavLink to={routes.company.view({ id: record.id })}>
              <strong>{value}</strong>
            </NavLink>
          ),
        },
        {
          title: "company.acn",
          dataIndex: "acn",
        },
        {
          title: "common.status",
          dataIndex: "status",
          filters: _uniqBy(data, "status").map((item) => ({
            value: item.statusID,
            text: item.status,
          })),
          onFilter: (value, record) =>
            value.toString() === record.statusID.toString(),
          render: ({ record: { statusID, status } }) => (
            <StatusTag id={statusID} name={status} />
          ),
        },
        {
          title: "date.createdAt",
          dataIndex: "createdAt",
          filterInputType: ColumnFilterInputTypes.DATE_RANGE,
          render: ({ value }) => dayjs(value).format(READABLE_DATE_TIME_FORMAT),
        },
        {
          title: "date.submittedAt",
          dataIndex: "submittedAt",
          filterInputType: ColumnFilterInputTypes.DATE_RANGE,
          render: ({ value }) =>
            value && dayjs(value).format(READABLE_DATE_TIME_FORMAT),
        },
      ]}
    />
  );
};

export default CompanyTable;

import { FC, useState } from "react";
import { Flex, Button, Form, Typography, FormInstance } from "antd";
import InputSelect from "../basic";
import { PlusOutlined } from "@ant-design/icons";
import { removeExSpaces } from "utils";
import { useClientAddresses } from "hooks";
import { createUseStyles } from "react-jss";
import { AddressDrawer } from "./components";

interface AddressSelectProps {
  name: string;
  label?: string;
  required?: boolean;
  justify?: "space-between";
  parentForm: FormInstance;
}

const useStyles = createUseStyles({
  address: {
    marginBottom: 24,
  },
  select: {
    flexGrow: 1,
    maxWidth: "90%",
  },
});

const AddressSelect: FC<AddressSelectProps> = ({
  name,
  required = true,
  justify,
  parentForm,
  label = "address.select",
}) => {
  const [open, toggleDrawer] = useState(false);
  const classes = useStyles();
  const { data, isLoading } = useClientAddresses();

  return (
    <>
      <AddressDrawer
        open={open}
        onClose={() => toggleDrawer(false)}
        onSuccess={(addressID) => {
          parentForm.setFieldValue(name, addressID);
        }}
      />
      <Flex gap={8} align="end" justify={justify}>
        <InputSelect
          required={required}
          name={name}
          className={classes.select}
          label={label}
          initialLoading={isLoading}
          options={data}
          filterOption={(input, opt) => {
            const value = input.toLowerCase();
            // TODO Refactor InputSelect(remove getOptionProps, add optionRender func, etc
            // eslint-disable-next-line
            // @ts-ignore
            return opt.children.props.children.some((item) => {
              if (!item) {
                return false;
              }

              return item.toLowerCase().indexOf(value) !== -1;
            });
          }}
          getOptionProps={(opt) => ({
            value: opt.id,
            children: (
              <>
                {opt.unitNumber && opt.unitNumber}
                &nbsp;
                {opt.streetNumber} {opt.streetName} {opt.streetType}{" "}
                {opt.suburb} {opt.state} {opt.country}
              </>
            ),
          })}
        />
        <Form.Item label={<p hidden>click</p>}>
          <Button icon={<PlusOutlined />} onClick={() => toggleDrawer(true)} />
        </Form.Item>
      </Flex>
      <Form.Item dependencies={[name]} noStyle>
        {({ getFieldValue }) => {
          const addressID = getFieldValue(name);
          const address = data.find((item) => item.id === addressID);

          if (!address) {
            return null;
          }

          const addressString1 = removeExSpaces(
            `${address.streetNumber} ${address.streetName} ${address.streetType}`,
          );
          const addressString2 = removeExSpaces(
            `${address.suburb} ${address.state} ${address.postcode}`,
          );
          return (
            <Typography.Paragraph className={classes.address}>
              <Typography.Text strong>Selected Address:</Typography.Text>
              <br />
              {address.unitNumber ? (
                <span>{address.unitNumber}&nbsp;/&nbsp;</span>
              ) : null}
              {addressString1}
              <br />
              {addressString2}
              <br />
              {address.country}
            </Typography.Paragraph>
          );
        }}
      </Form.Item>
    </>
  );
};

export default AddressSelect;

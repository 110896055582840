import { FC } from "react";
import { Icons, Button, Popconfirm, FormattedMessage } from "components";
import { useDeleteMutation } from "hooks";
import { selectors } from "../../../duck";

interface RemoveIconProps {
  cognitoID: string;
  companyID: number;
  itemID: number;
  subRoute: "officeholders" | "shareholders" | "share-capital";
  queryKeys: string[][];
}

const RemoveIcon: FC<RemoveIconProps> = ({
  cognitoID,
  companyID,
  queryKeys,
  itemID,
  subRoute,
}) => {
  const { isPending, mutateAsync } = useDeleteMutation<void, void>({
    apiName: "client",
    invalidateQueries: queryKeys,
    path: selectors.getUrlPath({
      companyID,
      cognitoID,
      path: subRoute,
      itemID,
    }),
  });

  if (subRoute === "share-capital") {
    return (
      <Popconfirm
        title={<FormattedMessage id="confirmations.shareCapital" />}
        okText={<FormattedMessage id="button.ok" />}
        cancelText={<FormattedMessage id="button.cancel" />}
        okButtonProps={{ loading: isPending }}
        onConfirm={async () => {
          await mutateAsync();
        }}
      >
        <Button
          type="text"
          htmlType="button"
          danger
          icon={<Icons.DeleteOutlined />}
        />
      </Popconfirm>
    );
  }

  return (
    <Button
      type="text"
      htmlType="button"
      loading={isPending}
      onClick={() => mutateAsync()}
      danger
      icon={<Icons.DeleteOutlined />}
    />
  );
};

export default RemoveIcon;

import { FC } from "react";
import { StepButtons, CompletePayment, List } from "components";
import { useQuery, useStripeScript } from "hooks";
import { formatToCurrency, getQueryKey } from "utils";
import { hooks } from "../duck";
import { Elements } from "@stripe/react-stripe-js";
import { Types } from "../../duck";

const Confirm: FC<Types.DTStepProps> = ({
  discretionaryTrust,
  queryKey,
  cognitoID,
  onNextClick,
  ...props
}) => {
  const { data: paymentData } = useQuery<{
    client_secret: string;
    total: number;
  }>({
    apiName: "client",
    staleTime: 0,
    path: `/${cognitoID}/discretionary-trust/${discretionaryTrust.id}/payment`,
    queryKey: getQueryKey("discretionaryTrustPayment", discretionaryTrust.id),
  });
  const { trustDocumentsDistribution } = hooks.useTrustDocumentsDistribution();

  const stripeScript = useStripeScript();

  const trustDocumentDistribution = trustDocumentsDistribution.find(
    (item) => item.id === discretionaryTrust.documentsDistributionID,
  );

  return (
    <>
      {paymentData?.client_secret && (
        <Elements
          stripe={stripeScript}
          options={{
            clientSecret: paymentData.client_secret,
          }}
        >
          <CompletePayment total={paymentData.total}>
            {({ loading, disabled }) => (
              <StepButtons
                {...props}
                loading={loading}
                nextDisabled={disabled}
              />
            )}
          </CompletePayment>
        </Elements>
      )}

      <List
        size="small"
        header={<strong>Price</strong>}
        bordered
        dataSource={[
          {
            label: "Trust discretionary establishment",
            price: 129,
          },
          ...(!trustDocumentDistribution
            ? []
            : [
                {
                  label: `Document distribution (${trustDocumentDistribution.type})`,
                  price: trustDocumentDistribution.price,
                },
              ]),
        ]}
        renderItem={(item) => (
          <List.Item>
            {item.label}: <strong>{formatToCurrency(item.price)}</strong>
          </List.Item>
        )}
      />
    </>
  );
};

export default Confirm;

import { FC, MutableRefObject } from "react";
import { Form, Input, InputRef, FormItemProps } from "antd";
import { FormattedMessage } from "react-intl";

interface InputTextAreaProps extends FormItemProps {
  placeholder?: string;
  label?: string;
  required?: boolean;
  autoFocus?: boolean;
  rows?: number;
  maxLength?: number;
  formItemClassName?: string;
  innerRef?: MutableRefObject<InputRef | null>;
}

const InputTextArea: FC<InputTextAreaProps> = ({
  name,
  rows = 6,
  maxLength,
  autoFocus,
  placeholder,
  rules = [],
  required,
  label,
  innerRef,
  className,
  style,
  formItemClassName,
}) => {
  return (
    <Form.Item
      label={label && <FormattedMessage id={label} />}
      name={name}
      normalize={(value) => value.replace(/\s{2,}/g, " ")}
      className={formItemClassName}
      rules={[
        {
          required,
          message: "Can't be blank",
        },
        ...rules,
      ]}
    >
      <Input.TextArea
        rows={rows}
        maxLength={maxLength}
        className={className}
        style={style}
        ref={innerRef}
        placeholder={placeholder}
        autoComplete="new-password"
        autoFocus={autoFocus}
      />
    </Form.Item>
  );
};
export default InputTextArea;

import { AccountSlugs, AccountSlug } from "types";

interface Args {
  id: string | number;
  childID?: string | number;
}

export default {
  // dashboard
  dashboard: "/dashboard",
  // about
  about: "/about",
  // login
  login: "/login",
  // register
  register: (accountSlug: AccountSlug) =>
    `/register/${AccountSlugs[accountSlug]}`,

  discretionaryTrust: {
    main: "/discretionary-trust",
    create() {
      return `${this.main}/create`;
    },
    details(id?: number) {
      return `${this.main}/${id || ":id"}`;
    },
    list() {
      return `${this.main}/list`;
    },
  },

  company: {
    main: "/companies",
    list() {
      return `${this.main}/list`;
    },
    about() {
      return `${this.main}/about`;
    },
    types() {
      return `/types`;
    },
    create() {
      return `${this.main}/create`;
    },
    view(args?: Args) {
      return `${this.main}/${args?.id || ":id"}`;
    },
    updateStep({
      id,
      step,
      cognitoID,
    }: {
      id: number;
      step: number;
      cognitoID: string;
    }) {
      return `/${cognitoID}/companies/${id}/step-${step}`;
    },
  },

  settings: {
    main: "/settings",
    userProfile() {
      return `${this.main}/profile`;
    },
  },
};

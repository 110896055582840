import { useQuery } from "./tanstack";
import { Address as AddressType } from "types";
import { getQueryKey } from "utils";
import { useSelector } from "./useReactRedux";

const useClientAddresses = () => {
  const clientID = useSelector((state) => state.auth.client?.id as number);

  const queryKey = getQueryKey("clientAddresses", clientID);

  const { data = [], isLoading } = useQuery<AddressType[]>({
    queryKey: getQueryKey("clientAddresses", clientID),
    apiName: "client",
    path: `/${clientID}/address`,
  });

  return {
    data,
    isLoading,
    queryKey,
  };
};

export default useClientAddresses;

import * as React from "react";
import * as C from "components";
import { useLocation, useSelector } from "hooks";
import { Header, Menu, Login, Footer } from "./components";
import { Navigate, Route } from "components";
import { createUseStyles } from "react-jss";
import { constants, operations } from "./duck";
import { routes } from "configs";

export const useStyles = createUseStyles({
  root: {
    background: "#fff",
    height: "100%",
    overflow: "hidden",
    "& .ant-layout .ant-layout-sider": {
      background: "#fff",
    },
  },
  contentWrapper: {
    minHeight: 360,
    background: "#fff",
    position: "relative",
    overflowX: "hidden",
  },
  bgWhite: {
    backgroundColor: "#fff",
  },
  content: {
    margin: "14px 0 0 14px",
    background: "#fff",
    overflowY: "auto",
  },
});

const Layout: React.FC = () => {
  const { pathname, search } = useLocation();
  const classes = useStyles();
  const redirect = operations.queryString("redirect");
  const authenticated = useSelector((state) => !!state.auth.client);

  return (
    <C.Layout className={classes.root}>
      <Header />
      <C.Layout.Content className={classes.contentWrapper}>
        <C.Layout className={classes.root}>
          {authenticated && <Menu />}
          <C.Layout.Content className={classes.content}>
            <C.Routes>
              <Route
                path={routes.login}
                element={
                  !authenticated ? (
                    <Login />
                  ) : (
                    <Navigate to={redirect || routes.dashboard} replace />
                  )
                }
              />
              {constants.OPEN_ROUTES.map(({ path, Component }, i) => {
                if (Array.isArray(path)) {
                  return (
                    // eslint-disable-next-line
                    <Route key={i}>
                      {path.map((item) => (
                        <Route key={item} path={item} element={<Component />} />
                      ))}
                    </Route>
                  );
                }

                return <Route path={path} element={<Component />} key={path} />;
              })}
              {constants.SECURED_ROUTES.map(({ Component, path }, i) => {
                const element = authenticated ? (
                  <Component />
                ) : (
                  <Navigate
                    to={`${routes.login}?redirect=${pathname}${search}`}
                    replace
                  />
                );

                if (Array.isArray(path)) {
                  return (
                    // eslint-disable-next-line
                    <Route key={i}>
                      {path.map((item) => (
                        <Route key={item} path={item} element={element} />
                      ))}
                    </Route>
                  );
                }

                return <Route key={path} path={path} element={element} />;
              })}
              <C.Route
                path="/"
                element={
                  <C.Navigate
                    to={authenticated ? routes.dashboard : routes.login}
                  />
                }
              />
              <C.Route path="*" element={<C.CatchAll />} />
            </C.Routes>
          </C.Layout.Content>
        </C.Layout>
      </C.Layout.Content>
      <Footer appVersion="0.2.0" />
    </C.Layout>
  );
};

export default Layout;

import { Flex, Skeleton } from "antd";

const DocumentsSkeleton = () => (
  <>
    <Flex vertical gap={14}>
      <Skeleton.Image active style={{ width: "100%", height: "150px" }} />
      <Skeleton.Input active style={{ width: "auto" }} />
    </Flex>
    <Flex vertical gap={14}>
      <Skeleton.Image active style={{ width: "100%", height: "150px" }} />
      <Skeleton.Input active style={{ width: "auto" }} />
    </Flex>
    <Flex vertical gap={14}>
      <Skeleton.Image active style={{ width: "100%", height: "150px" }} />
      <Skeleton.Input active style={{ width: "auto" }} />
    </Flex>
  </>
);

export default DocumentsSkeleton;

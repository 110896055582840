import { FC } from "react";
import { Form, Button, Drawer } from "antd";
import { FormattedMessage } from "react-intl";
import InputText from "components/input-text";
import AsyncSelect from "components/input-select/async-select";
import AddressSelect from "components/input-select/address-select";
import InputEmail from "components/input-email";
import InputDatePicker from "components/input-date-picker";
import { Country, Individual, QueryKey } from "types";
import { getQueryKey } from "utils";
import { useMutation, useSelector } from "hooks";
import { IndividualSelectProps, DrawerRequiredFields } from "../../types";

interface IndividualDrawerProps
  extends Pick<IndividualSelectProps, "parentForm"> {
  open: boolean;
  label: string;
  onClose: () => void;
  queryKey: QueryKey;
  requiredFields?: DrawerRequiredFields;
  formInputName: string;
  multi?: boolean;
}

const AddIndividualDrawer: FC<IndividualDrawerProps> = ({
  open,
  parentForm,
  onClose,
  queryKey,
  label,
  requiredFields,
  formInputName,
  multi,
}) => {
  const [form] = Form.useForm();
  const cognitoID = useSelector(
    (state) => state.auth.client?.cognitoID as string,
  );
  const { isPending, mutate } = useMutation<Individual>({
    apiName: "client",
    method: "post",
    path: `/${cognitoID}/individuals`,
    invalidateQueries: [queryKey],
    onSuccess: (newData) => {
      onClose();

      parentForm.setFields([
        {
          name: formInputName,
          value: !multi
            ? newData.id
            : [...parentForm.getFieldValue(formInputName), newData.id],
          touched: true,
        },
      ]);

      form.resetFields();
    },
  });

  const countryQueryKey = getQueryKey("countries");

  return (
    <Drawer
      open={open}
      destroyOnClose
      onClose={onClose}
      title={
        <FormattedMessage
          id="drawers.createNew"
          values={{ item: label.toLowerCase() }}
        />
      }
      footer={
        <Button
          type="primary"
          loading={isPending}
          onClick={() => form.submit()}
        >
          Submit
        </Button>
      }
    >
      <Form layout="vertical" form={form} onFinish={mutate}>
        <InputText
          required={requiredFields?.firstName}
          name="firstName"
          label="common.first_name"
        />
        <InputText name="middleName" label="common.middle_name" />
        <InputText
          required={requiredFields?.lastName}
          name="lastName"
          label="common.last_name"
        />
        <InputEmail required={requiredFields?.email} />
        <AddressSelect
          parentForm={form}
          name="addressID"
          justify="space-between"
          required={requiredFields?.addressID}
        />
        <InputDatePicker
          label="common.dateOfBirth"
          name="dateOfBirth"
          isAfterToday
          required={requiredFields?.dateOfBirth}
        />
        <InputText
          label="common.placeOfBirthCity"
          name="placeOfBirthCity"
          required={requiredFields?.placeOfBirthCity}
        />
        <AsyncSelect<Country>
          required={requiredFields?.placeOfBirthCountryID}
          label="common.placeOfBirthCountry"
          name="placeOfBirthCountryID"
          queryKey={countryQueryKey}
          apiName="system"
          path="/countries"
          getOptionProps={(opt) => ({
            value: opt.id,
            children: opt.full_name,
          })}
        />
        <AsyncSelect<Country>
          label="common.nationality"
          name="nationalityID"
          queryKey={countryQueryKey}
          apiName="system"
          path="/countries"
          getOptionProps={(opt) => ({
            value: opt.id,
            children: opt.full_name,
          })}
        />
      </Form>
    </Drawer>
  );
};

export default AddIndividualDrawer;

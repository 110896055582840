import { useState, FC } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { constants, operations, Types } from "./duck";
import { GOOGLE_API_KEY } from "consts";
import { useQuery } from "hooks";
import { getQueryKey } from "utils";
import { Country, CommonRecords } from "types";
import { FormattedMessage } from "react-intl";

const GoogleAutocomplete: FC<Types.GoogleAutocompleteProps> = ({
  onChange,
}) => {
  const { data: countries = [] } = useQuery<Country[]>({
    apiName: "system",
    path: "/countries",
    queryKey: getQueryKey("countries"),
  });
  const { data: streetTypes = [] } = useQuery<CommonRecords["streetTypes"]>({
    apiName: "system",
    path: "/street-types",
    queryKey: getQueryKey("streetTypes"),
  });
  const [googleAddress, setGoogleAddress] = useState<any>("");

  const handleSelectGoogleAddress = async (value: any | null) => {
    if (!value) {
      onChange(constants.INITIAL_VALUES);

      setGoogleAddress(value);
      return;
    }

    const addresses = await operations.getAddresses(
      value,
      countries,
      streetTypes,
    );

    onChange(addresses);

    setGoogleAddress(value);
  };

  return (
    <GooglePlacesAutocomplete
      apiKey={GOOGLE_API_KEY}
      selectProps={{
        value: googleAddress,
        isClearable: true,
        backspaceRemovesValue: true,
        onChange: handleSelectGoogleAddress,
        placeholder: <FormattedMessage id="googleAutocomplete.placeholder" />,
        noOptionsMessage: ({ inputValue }: any) => {
          if (!inputValue) {
            return <FormattedMessage id="googleAutocomplete.placeholder" />;
          }

          return "Not found";
        },
      }}
      autocompletionRequest={{
        componentRestrictions: { country: countries.map((c) => c.iso_alpha_2) },
      }}
    />
  );
};

export default GoogleAutocomplete;

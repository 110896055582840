import { useQuery, DefaultError } from "@tanstack/react-query";
import * as API from "aws-amplify/api";
import { operations, Types } from "./duck";

const useQueryRequest = <TQueryFnData, TData = TQueryFnData>({
  apiName,
  path = "",
  queryKey,
  enabled,
  select,
  staleTime,
  options,
}: Types.QueryArgs<TQueryFnData, TData>) => {
  return useQuery<TQueryFnData, DefaultError, TData>({
    queryKey,
    enabled,
    staleTime,
    select,
    queryFn: async ({ signal }) => {
      const promise = API.get({
        apiName,
        path,
        options,
      });

      signal.addEventListener("abort", () => {
        promise.cancel();
      });

      return operations.queryFnWrapper<TQueryFnData>(promise);
    },
  });
};

export default useQueryRequest;

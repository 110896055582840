import { FC, useState } from "react";
import { Button, Flex, Form, List, Typography } from "antd";
import InputSelect from "../basic";
import StatusTag from "../../status-tag";
import { PlusOutlined } from "@ant-design/icons";
import { Company } from "types";
import { useCompanies } from "hooks";
import { AddCompanyDrawer } from "./components";
import { CompanySelectProps } from "./types";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  select: {
    width: "100%",
  },
});

const CompanySelect: FC<CompanySelectProps> = ({
  parentForm,
  multi,
  maxCount,
  required = true,
  name = "companyID",
  label = "common.company",
  drawerRequiredFields,
  fetchOptions,
}) => {
  const [open, toggleOpen] = useState(false);
  const classes = useStyles();
  const {
    companies = [],
    companiesQueryKey,
    companiesLoading,
  } = useCompanies({
    registeredOnly: true,
    disabled: !!fetchOptions,
  });

  const options = fetchOptions?.options || companies;
  const loading = fetchOptions?.loading || companiesLoading;

  return (
    <>
      <AddCompanyDrawer
        multi={multi}
        parentForm={parentForm}
        formInputName={name}
        queryKey={companiesQueryKey}
        open={open}
        requiredFields={drawerRequiredFields}
        onClose={() => toggleOpen(false)}
      />
      <Flex justify="space-between" align="end" gap={8}>
        <InputSelect<Company>
          required={required}
          name={name}
          label={label}
          preserve={false}
          mode={multi ? "multiple" : undefined}
          maxCount={maxCount}
          className={classes.select}
          options={options}
          initialLoading={loading}
          getOptionProps={(opt) => ({
            value: opt.id,
            children: `${opt.name} ${opt.acn || ""}`,
          })}
        />
        <Form.Item label={<p hidden>click</p>}>
          <Button icon={<PlusOutlined />} onClick={() => toggleOpen(true)} />
        </Form.Item>
      </Flex>
      <Form.Item noStyle dependencies={[name]}>
        {({ getFieldValue }) => {
          const value = getFieldValue(name);

          const result = !Array.isArray(value)
            ? companies.find((item) => item.id === value)
            : companies.filter((item) => value.includes(item.id));

          if (!result) {
            return null;
          }

          return (
            <List
              bordered
              dataSource={Array.isArray(result) ? result : [result]}
              renderItem={(item) => (
                <List.Item key={item.id}>
                  <Typography.Text>
                    {item.name} {item.acn}
                  </Typography.Text>
                  <StatusTag id={item.statusID} name={item.status} />
                </List.Item>
              )}
            />
          );
        }}
      </Form.Item>
    </>
  );
};

export default CompanySelect;

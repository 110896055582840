import * as React from "react";
import { Skeleton, Row, Col, StepsSkeleton, Flex } from "components";
import { COMMON_COL_PROPS, ROW_GUTTER } from "consts";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import { constants } from "../../duck";

// TODO
const useStyles = createUseStyles({
  common: {
    height: 20,
    marginBottom: 24,
  },
  companyName: {
    "&.ant-skeleton.ant-skeleton-element ": {
      "& .ant-skeleton-input": {
        width: 200,
      },
    },
  },
  companyType: {
    "&.ant-skeleton.ant-skeleton-element ": {
      "& .ant-skeleton-input": {
        width: 100,
      },
    },
  },
  jurisdiction: {
    "&.ant-skeleton.ant-skeleton-element ": {
      "& .ant-skeleton-input": {
        width: 400,
      },
    },
  },
  instructions: {
    "&.ant-skeleton.ant-skeleton-element ": {
      width: "100%",
      height: 100,
      display: "inline-block",
      "& .ant-skeleton-input": {
        width: "100%",
        height: "100%",
      },
    },
  },
});

const SkeletonFinancial: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <StepsSkeleton steps={constants.STEP_TITLES} />
      <Skeleton.Input active size="small" className={classes.instructions} />
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <Skeleton title active paragraph={{ rows: 0 }} />
          <Flex gap={8}>
            <Skeleton.Input
              active
              size="small"
              className={classNames(classes.common, classes.companyName)}
            />
            <Skeleton.Input
              active
              size="small"
              className={classNames(classes.common, classes.companyType)}
            />
          </Flex>
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <Skeleton title active paragraph={{ rows: 0 }} />
          <Skeleton.Input
            active
            size="small"
            className={classNames(classes.common, classes.jurisdiction)}
          />
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <Skeleton title active paragraph={{ rows: 1 }} />
          <Skeleton.Button size="small" shape="circle" />
          <Skeleton title active paragraph={{ rows: 1 }} />
          <Skeleton.Button size="small" shape="circle" />
          <Skeleton title active paragraph={{ rows: 1 }} />
          <Skeleton.Button size="small" shape="circle" />
        </Col>
      </Row>
    </>
  );
};

export default SkeletonFinancial;

import { FC } from "react";
import {
  Drawer,
  Form,
  Button,
  FormattedMessage,
  AsyncSelect,
  FormItemInputNumber,
  Typography,
  Popover,
} from "components";
import { ShareClass, ShareCapital } from "types";
import { getQueryKey, formatToCurrency } from "utils";
import { useMutation } from "hooks";
import { selectors } from "../../../../duck";

interface AddNewDrawerProps {
  companyID: number;
  cognitoID: string;
  shareCapital: ShareCapital[];
  onClose: () => void;
  open: boolean;
}

const AddNewDrawer: FC<AddNewDrawerProps> = ({
  open,
  companyID,
  cognitoID,
  shareCapital,
  onClose,
}) => {
  const [form] = Form.useForm();
  const { isPending, mutate } = useMutation({
    method: "post",
    apiName: "client",
    invalidateQueries: [getQueryKey("companyShareCapital", companyID)],
    path: selectors.getUrlPath({
      companyID,
      cognitoID,
      path: "share-capital",
    }),
    onSuccess: () => {
      onClose();
    },
  });

  return (
    <Drawer
      open={open}
      destroyOnClose
      onClose={onClose}
      title={<FormattedMessage id="company.fourthStep.addNewTitle" />}
      footer={
        <Button
          type="primary"
          loading={isPending}
          onClick={() => form.submit()}
        >
          Submit
        </Button>
      }
    >
      <Form form={form} layout="vertical" preserve={false} onFinish={mutate}>
        <AsyncSelect<ShareClass>
          required
          name="shareClassID"
          label="company.shareClass"
          apiName="system"
          path="/share-classes"
          queryKey={getQueryKey("shareClasses")}
          getOptionProps={(opt) => {
            const disabled = shareCapital.some(
              (item) => item.shareClassID === opt.id,
            );
            const label = `${opt.description} (${opt.code})`;

            return {
              disabled,
              value: opt.id,
              children: !disabled ? (
                label
              ) : (
                <Popover
                  title={null}
                  placement="left"
                  content={<FormattedMessage id="company.shareClassSelected" />}
                  zIndex={1100}
                >
                  {label}
                </Popover>
              ),
            };
          }}
        />
        <FormItemInputNumber
          name="price"
          label="common.price"
          greaterThan={0}
          max={100000}
          precision={2}
          format
          required
        />
        <FormItemInputNumber
          required
          name="quantity"
          label="common.quantity"
          equalOrMore={1}
          step={1}
        />
        <Form.Item dependencies={["price", "quantity"]} noStyle>
          {({ getFieldsValue }) => {
            const { price, quantity } = getFieldsValue(["price", "quantity"]);
            let total: number | string = 0;

            if (price && quantity) {
              total = formatToCurrency(price * quantity);
            }

            return (
              <Typography.Title level={5}>
                <FormattedMessage id="common.total" />: {total}
              </Typography.Title>
            );
          }}
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddNewDrawer;

import { ReactElement } from "react";
import { TrustStatusEnum } from "types";
import { Alert } from "components";

export const STEPS = [
  "common.trust",
  "common.trustee",
  "discretionaryTrust.relevantPersons",
  "common.beneficiaries",
  "common.documents",
  "common.confirm",
];

export const TRUST_STATUS_UI: Record<number, ReactElement> = {
  [TrustStatusEnum.Pending]: (
    <Alert
      type="success"
      message="Discretionary trust has been received by ASIC and is in process"
    />
  ),
  [TrustStatusEnum.Submitted]: (
    <Alert type="success" message="Discretionary trust  has been submitted" />
  ),
  [TrustStatusEnum.Registered]: <div>Registered...TBD</div>,
} as const;

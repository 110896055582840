import { ReactNode } from "react";
import { Empty, Skeleton as AntSkeleton } from "components";
import { useParams, useSelector, useQuery } from "hooks";
import { ApiNames, Params } from "types";

const viewHOC =
  <TRecord = any,>({
    Component,
    pathFn,
    queryKeyFn,
    apiName,
    skeleton = <AntSkeleton active loading />,
  }: {
    Component: any;
    queryKeyFn: (args: {
      params: Params<"id">;
      cognitoID: string;
    }) => Array<string | number>;
    apiName: ApiNames;
    pathFn?: (args: {
      params: Params<"id">;
      accountID: number;
      cognitoID: string;
    }) => string;
    skeleton?: ReactNode;
  }) =>
  (props: any) => {
    const params = useParams<"id">();
    const { accountID, cognitoID } = useSelector((root) => ({
      accountID: root.auth.account?.id as number,
      cognitoID: root.auth.client?.cognitoID as string,
    }));
    const queryKey = queryKeyFn({
      params,
      cognitoID,
    });

    const { data, isLoading } = useQuery<TRecord>({
      queryKey,
      apiName,
      path: pathFn?.({ params, accountID, cognitoID }),
    });

    if (isLoading) {
      return skeleton as any;
    }

    if (!data && !isLoading) {
      return <Empty />;
    }

    return <Component {...props} record={data} queryKey={queryKey} />;
  };

export default viewHOC;

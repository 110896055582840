export const ACCEPTED_FILES = ".pdf, .doc, .docx, .jpg, .jpeg, .png, .msword";

export const COMMON_COL_PROPS = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 12,
};

export const ROW_GUTTER = {
  xs: 8,
  sm: 16,
  md: 24,
  lg: 32,
};

export const BREAKPOINTS = {
  MD: "768px",
};

export const MEDIA_QUERY = {
  mobile: `@media (max-width: ${BREAKPOINTS.MD})`,
};

export const INPUT_GROUP_OPTIONS = [
  {
    label: "common.yes",
    value: true,
    translate: true,
  },
  {
    label: "common.no",
    value: false,
    translate: true,
  },
];

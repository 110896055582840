import { FC, useState, ReactElement, useEffect } from "react";
import { ExtendedSteps, Alert } from "components";
import * as LC from "./components";
import { constants, Types } from "./duck";
import { getQueryKey, getCompaniesQueryKey } from "utils";
import { routes } from "configs";
import { viewHOC } from "hocs";
import { useMutation, useSelector } from "hooks";
import { Company, CompanyStatus } from "types";

const CompanyDetails: FC<Types.HocProps> = ({
  record,
  getTopics,
  queryKey,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const cognitoID = useSelector(
    (state) => state.auth.client?.cognitoID as string,
  );

  useEffect(() => {
    if (record.name) {
      getTopics({
        extra: <strong>{record.name}</strong>,
      });
    }
  }, [record.name, getTopics]);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  // TODO refactor. Move useMutations to the components they use
  const mutation = useMutation<Company, Partial<Company>>({
    apiName: "client",
    method: "patch",
    invalidateQueries: [getCompaniesQueryKey(cognitoID), queryKey],
    onSuccess: () => {
      nextStep();
    },
    path: routes.company.updateStep({
      // 1, 2, 6, 7 steps
      cognitoID,
      id: record.id,
      step: currentStep + 1,
    }),
  });

  const COMPANY_STATUS: Record<number, ReactElement> = {
    [CompanyStatus.Pending]: (
      <Alert
        type="success"
        message="Company Application has been received by ASIC and is in process"
      />
    ),
    [CompanyStatus.Submitted]: (
      <Alert type="success" message="Company Application has been submitted" />
    ),
    [CompanyStatus.Registered]: (
      <LC.CompanyTabs cognitoID={cognitoID} company={record} />
    ),
    [CompanyStatus.Deregistered]: (
      <LC.CompanyTabs cognitoID={cognitoID} company={record} />
    ),
  };

  const element = COMPANY_STATUS[record.statusID];

  if (element) {
    return element;
  }

  const commonProps = {
    company: record,
    cognitoID,
    loading: mutation.isPending,
    steps: constants.STEP_TITLES,
    currentStep,
    onNextClick: async (values?: Partial<Company>, touched?: boolean) => {
      if (!values || !touched) {
        nextStep();

        return;
      }

      mutation.mutate(values);
    },
    onPrevClick: () => {
      setCurrentStep(currentStep - 1);
    },
  };

  const stepComponents = [
    <LC.Company {...commonProps} />,
    <LC.Address {...commonProps} />,
    <LC.Officeholders {...commonProps} />,
    <LC.ShareCapital {...commonProps} />,
    <LC.Shareholders {...commonProps} />,
    <LC.Documents {...commonProps} />,
    <LC.Options {...commonProps} />,
    <LC.Confirm {...commonProps} companyQueryKey={queryKey} />,
  ];

  return (
    <>
      <ExtendedSteps steps={constants.STEP_TITLES} currentStep={currentStep} />
      {stepComponents[currentStep]}
    </>
  );
};

export default viewHOC({
  Component: CompanyDetails,
  skeleton: <LC.Skeleton />,
  queryKeyFn: ({ params, cognitoID }) =>
    getQueryKey("company", cognitoID, params.id),
  apiName: "client",
  pathFn: ({ params, cognitoID }) => `/${cognitoID}/companies/${params.id}`,
});

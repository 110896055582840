import { FC } from "react";
import { Tabs } from "components";
import * as LC from "./components";

const ClientProfile: FC = () => {
  return (
    <Tabs
      items={[
        {
          key: "Summary",
          label: "Summary",
          children: <LC.Summary />,
        },
        {
          key: "Contact",
          label: "Contact",
          children: <LC.Contact />,
        },
        {
          key: "Billing",
          label: "Billing",
          children: <LC.Billing />,
        },
      ]}
    />
  );
};

export default ClientProfile;

import { FC, useCallback } from "react";
import {
  Button,
  Col,
  Divider,
  FormattedMessage,
  Image,
  Row,
  Flex,
  Typography,
  Form,
  InputText,
} from "components";
import { dashboardLogo } from "assets";
import _debounce from "lodash/debounce";
import { useNavigate, useMutation, useSelector } from "hooks";
import { getCompaniesQueryKey } from "utils";
import { routes } from "configs";
import { Company } from "types";

type Variables = Pick<Company, "name">;

const CompanyCreate: FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const cognitoID = useSelector(
    (state) => state.auth.client?.cognitoID as string,
  );

  const { mutate: createCompany, isPending: companyCreating } = useMutation<
    Company,
    Pick<Company, "name">
  >({
    apiName: "client",
    path: `/${cognitoID}/companies`,
    method: "post",
    invalidateQueries: [getCompaniesQueryKey(cognitoID)],
    onSuccess: (company) => {
      navigate(
        routes.company.view({
          id: company.id,
        }),
      );
    },
  });

  const {
    data: existingItems = [],
    isPending: searching,
    mutate: search,
  } = useMutation<Company[], Variables>({
    apiName: "client",
    path: `/${cognitoID}/companies/search`,
    method: "post",
    onSuccess: (result) => {
      form.setFields([
        {
          name: "name",
          errors: !result.length
            ? []
            : ["A record with the given name already exists"],
        },
      ]);
    },
  });
  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    _debounce((name) => search({ name }), 400),
    [],
  );

  const loading = companyCreating || searching;
  const createDisabled = searching || !!existingItems.length;

  return (
    <>
      <Row>
        <Col xs={24} sm={15} md={15} lg={15}>
          <Typography.Title level={4}>
            <FormattedMessage id="company.splashTitle" />
          </Typography.Title>
          <Divider />
          <Typography.Paragraph>
            <FormattedMessage
              id="company.splashDescription"
              values={{
                br: (
                  <>
                    <br />
                    <br />
                  </>
                ),
                b: (chunks) => <b>{chunks}</b>,
              }}
            />
          </Typography.Paragraph>
          <Typography.Paragraph>
            <em>
              Note: You can check your desired company name is not yet taken
              at:&nbsp;
              <a
                href="https://connectonline.asic.gov.au/RegistrySearch/faces/landing/SearchRegisters.jspx"
                target="_blank"
                rel="noreferrer"
              >
                ASIC Company Name Search
              </a>
            </em>
          </Typography.Paragraph>
        </Col>
        <Col xs={24} sm={9} md={9} lg={9}>
          <Image src={dashboardLogo} preview={false} />
        </Col>
      </Row>
      <Divider />
      <Form<Variables>
        layout="vertical"
        form={form}
        onValuesChange={(values) => {
          if (values.name) {
            debouncedSearch(values.name);
          }
        }}
        onFinish={({ name }) => {
          if (createDisabled) {
            return;
          }

          createCompany({
            name,
          });
        }}
      >
        <Flex gap={8} align="center">
          <InputText
            required
            placeholder="Type it here"
            name="name"
            label="company.newName"
            className="animate__animated animate__fadeInRight"
          />
          <Form.Item label={<span>&nbsp;</span>}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={createDisabled}
              className="animate__animated animate__fadeInLeft"
            >
              <FormattedMessage
                id={searching ? "common.justAMoment" : "button.start"}
              />
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </>
  );
};

export default CompanyCreate;

import { Operation, RestApiResponse } from "types";
import * as API from "aws-amplify/api";
import { notification } from "antd";

export const queryFnWrapper = async <TData>(
  operation: Operation<RestApiResponse | Omit<RestApiResponse, "body">>,
) => {
  try {
    const resp = await operation.response;

    if (!("body" in resp)) {
      return resp as TData;
    }

    return (await resp.body.json()) as TData;
  } catch (e: any) {
    let { message } = e;

    if (API.isCancelError(e) || message.toLowerCase().includes("abort")) {
      return Promise.reject(message);
    }

    if (e instanceof API.ApiError && e.response?.body) {
      const parsed = JSON.parse(e.response.body);

      message = parsed.error || parsed.message;
    }

    notification.error({
      message,
    });

    return Promise.reject({ message });
  }
};

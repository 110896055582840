import { useState, FC } from "react";
import { ExtendedSteps } from "components";
import { useSelector } from "hooks";
import { getQueryKey } from "utils";
import * as LC from "./components";
import _omit from "lodash/omit";
import { viewHOC } from "hocs";
import { TrustDiscretionary, ViewHOCChildProps } from "types";
import { constants } from "./duck";

const DiscretionaryTrustCreate: FC<ViewHOCChildProps<TrustDiscretionary>> = ({
  record,
  queryKey,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const cognitoID = useSelector(
    (state) => state.auth.client?.cognitoID as string,
  );

  const element = constants.TRUST_STATUS_UI[record.statusID];

  if (element) {
    return element;
  }

  const onPrevClick = () => {
    setCurrentStep(currentStep - 1);
  };

  const commonProps = {
    steps: constants.STEPS,
    discretionaryTrust: record,
    cognitoID,
    queryKey,
    currentStep,
    onPrevClick,
    onNextClick: () => {
      setCurrentStep(currentStep + 1);
    },
  };

  const stepComponents = [
    <LC.Trust {...commonProps} />,
    <LC.Trustee {...commonProps} />,
    <LC.RelevantPersons {...commonProps} />,
    <LC.Beneficiaries {...commonProps} />,
    <LC.Documents {...commonProps} />,
    <LC.Confirm {..._omit(commonProps, ["onNextClick"])} />,
  ];

  return (
    <>
      <ExtendedSteps steps={commonProps.steps} currentStep={currentStep} />
      {stepComponents[currentStep]}
    </>
  );
};

export default viewHOC({
  apiName: "client",
  skeleton: <LC.Skeleton />,
  Component: DiscretionaryTrustCreate,
  pathFn: ({ cognitoID, params }) =>
    `/${cognitoID}/discretionary-trust/${params.id}`,
  queryKeyFn: ({ params, cognitoID }) =>
    getQueryKey("discretionaryTrust", cognitoID, params.id),
});

import { FC, useState } from "react";
import { Button, Flex, Form, List, Typography } from "antd";
import InputSelect from "../basic";
import { PlusOutlined } from "@ant-design/icons";
import { useIndividuals } from "hooks";
import { DATE_FORMAT } from "consts";
import { createUseStyles } from "react-jss";
import { useIntl } from "react-intl";
import { AddIndividualDrawer } from "./components";
import { IndividualSelectProps } from "./types";
import dayjs from "dayjs";

const useStyles = createUseStyles({
  select: {
    width: "100%",
  },
});

const IndividualSelect: FC<IndividualSelectProps> = ({
  parentForm,
  multi,
  maxCount,
  required = true,
  name = "individualID",
  label = "common.individual",
  drawerRequiredFields,
  fetchOptions,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [open, toggleOpen] = useState(false);
  const {
    individuals = [],
    individualsLoading,
    individualsQueryKey,
  } = useIndividuals(!!fetchOptions);

  const options = fetchOptions?.options || individuals;
  const loading = fetchOptions?.loading || individualsLoading;

  return (
    <>
      <AddIndividualDrawer
        open={open}
        multi={multi}
        queryKey={individualsQueryKey}
        label={intl.formatMessage({ id: label })}
        parentForm={parentForm}
        formInputName={name}
        onClose={() => toggleOpen(false)}
        requiredFields={drawerRequiredFields}
      />
      <Flex justify="space-between" align="end" gap={8}>
        <InputSelect
          label={label}
          required={required}
          name={name}
          mode={multi ? "multiple" : undefined}
          preserve={false}
          className={classes.select}
          maxCount={maxCount}
          options={options}
          initialLoading={loading}
          getOptionProps={(opt) => ({
            value: opt.id,
            children: `${opt.firstName} ${opt.lastName} ${
              opt.middleName || ""
            }`,
          })}
        />
        <Form.Item label={<p hidden>click</p>}>
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              toggleOpen(true);
            }}
          />
        </Form.Item>
      </Flex>
      <Form.Item noStyle dependencies={[name]}>
        {({ getFieldValue }) => {
          const value = getFieldValue(name);

          const result = !Array.isArray(value)
            ? individuals.find((item) => item.id === value)
            : individuals.filter((item) => value.includes(item.id));

          if (!result) {
            return null;
          }

          return (
            <List
              bordered
              dataSource={Array.isArray(result) ? result : [result]}
              renderItem={(item) => (
                <List.Item key={item.id}>
                  <Typography.Text>
                    {item.firstName} {item.lastName}{" "}
                    {dayjs(item.dateOfBirth).format(DATE_FORMAT)}
                  </Typography.Text>{" "}
                  {item.email}
                </List.Item>
              )}
            />
          );
        }}
      </Form.Item>
    </>
  );
};

export default IndividualSelect;

import { Address, Company, CompanyDocumentDistribution } from "types";
import { useQueries, useQuery } from "hooks";
import { getQueryKey } from "utils";

const getAddressItems = (address?: Address) => [
  {
    label: "common.country",
    value: address?.country,
  },
  {
    label: "address.unitNumber",
    value: address?.unitNumber,
  },
  {
    label: "address.streetNumber",
    value: address?.streetNumber,
  },
  {
    label: "address.streetName",
    value: address?.streetName,
  },
  {
    label: "address.streetType",
    value: address?.streetType,
  },
  {
    label: "address.boxType",
    value: address?.boxType,
  },
  {
    label: "address.boxNumber",
    value: address?.boxNumber,
  },
  {
    label: "address.suburb",
    value: address?.suburb,
  },
  {
    label: "address.state",
    value: address?.state,
  },
  {
    label: "address.postcode",
    value: address?.postcode,
  },
];

export const useCompanyAddresses = (company: Company) => {
  const [
    { data: registeredOffice, isLoading: registeredOfficeLoading },
    { data: ppb, isLoading: ppbLoading },
  ] = useQueries<Address>({
    queries: [
      {
        apiName: "address",
        path: `/${company.registeredOfficeID}`,
        enabled: !!company.principalPlaceOfBusinessID,
        queryKey: getQueryKey("registeredOffice", company.registeredOfficeID),
      },
      {
        apiName: "address",
        enabled: !!company.principalPlaceOfBusinessID,
        path: `/${company.principalPlaceOfBusinessID}`,
        queryKey: getQueryKey(
          "principalPlaceOfBusiness",
          company.principalPlaceOfBusinessID,
        ),
      },
    ],
  });

  return {
    ppb,
    ppbItems: getAddressItems(ppb),
    ppbLoading,
    registeredOffice,
    registeredOfficeItems: getAddressItems(registeredOffice),
    registeredOfficeLoading,
  };
};

export const useDocumentDistribution = () => {
  const { data = [], isLoading } = useQuery<CompanyDocumentDistribution[]>({
    apiName: "companies",
    path: "/document-distributions",
    queryKey: getQueryKey("documentDistributions"),
  });

  return {
    data,
    isLoading,
  };
};

import { InputPassword } from "components";

const NewPassword = () => {
  return (
    <>
      <InputPassword
        required
        label="password.old"
        name="oldPassword"
        dependencies={["newPassword"]}
      />
      <InputPassword
        required
        label="password.new"
        name="newPassword"
        preserve={false}
      />
    </>
  );
};

export default NewPassword;

import { FC } from "react";
import {
  Row,
  Col,
  Typography,
  FormattedMessage,
  ExtendedDescriptions,
  Spin,
} from "components";
import { COMMON_COL_PROPS, ROW_GUTTER } from "consts";
import { hooks } from "../../../../duck";
import { TabProps } from "../../types";

const AddressProps: FC<TabProps> = ({ company }) => {
  const {
    ppbItems,
    ppbLoading,
    registeredOfficeItems,
    registeredOfficeLoading,
  } = hooks.useCompanyAddresses(company);

  return (
    <Row gutter={ROW_GUTTER}>
      <Col {...COMMON_COL_PROPS}>
        <Typography.Title level={4}>
          <FormattedMessage id="company.secondStep.registeredOffice" />
        </Typography.Title>
        <Spin spinning={registeredOfficeLoading}>
          <ExtendedDescriptions items={ppbItems} />
        </Spin>
      </Col>
      <Col {...COMMON_COL_PROPS}>
        <Typography.Title level={4}>
          <FormattedMessage id="company.secondStep.principalPlaceOfBusiness" />
        </Typography.Title>
        <Spin spinning={ppbLoading}>
          <ExtendedDescriptions items={registeredOfficeItems} />
        </Spin>
      </Col>
    </Row>
  );
};

export default AddressProps;

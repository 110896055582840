import { Form } from "antd";

export const { useForm, useFormInstance } = Form;

export * from "./tanstack";

export * from "./useReactRedux";

export { default as useStripeScript } from "./useStripeScript";

export { default as useChildAPI } from "./use-child-api";
export { default as useClientAddresses } from "./useClientAddresses";
export { default as useCompanyHolders } from "./use-company-holders";
export { default as useCompanyShareCapital } from "./useCompanyShareCapital";
export { default as useIndividuals } from "./useIndividuals";
export { default as useCompanies } from "./useCompanies";

export { default as useQueryParams } from "./use-query-params";

export { default as usePrevious } from "./usePrevious";

export {
  useLocation,
  useMatch,
  useParams,
  useNavigate,
} from "react-router-dom";

export { useIntl } from "react-intl";

export { default as useNotification } from "./useNotification";

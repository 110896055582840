import { useState } from "react";
import {
  InputText,
  InputEmail,
  FormattedMessage,
  Row,
  Col,
  Button,
  Form,
  SubmitButton,
  Icons,
} from "components";
import { authAsyncActions } from "configs";
import { useSelector, useDispatch } from "hooks";
import { ROW_GUTTER, COMMON_COL_PROPS } from "consts";
import { ChangePasswordModal } from "./components";
import { Client } from "types";

const Summary = () => {
  const { client, loading, mobile } = useSelector((state) => ({
    client: state.auth.client as Client,
    loading: state.auth.loading,
    mobile: state.appState.breakpoint.mobile,
  }));
  const dispatch = useDispatch();
  const [modalVisible, toggleModal] = useState(false);

  return (
    <>
      <ChangePasswordModal
        open={modalVisible}
        onCancel={() => {
          toggleModal(false);
        }}
      />
      <Form
        name="summary"
        layout="vertical"
        initialValues={client}
        onFinish={(values) => {
          dispatch(
            authAsyncActions.updateClient({
              id: client.id,
              ...values,
            }),
          );
        }}
      >
        <Row gutter={ROW_GUTTER}>
          <Col {...COMMON_COL_PROPS}>
            <Row gutter={[8, 8]}>
              <Col xs={24} sm={12}>
                <InputText
                  required
                  label="common.first_name"
                  name="firstName"
                />
              </Col>
              <Col xs={24} sm={12}>
                <InputText required label="common.last_name" name="lastName" />
              </Col>
            </Row>
          </Col>
          <Col {...COMMON_COL_PROPS}>
            <InputEmail label="common.emailAddress" disabled />
            <Row justify={mobile ? "center" : "start"}>
              <Form.Item
                label={<FormattedMessage id="button.changePassword" />}
              >
                <Row justify={mobile ? "center" : "start"}>
                  <Button
                    icon={<Icons.ApiOutlined />}
                    onClick={() => {
                      toggleModal(true);
                    }}
                  />
                </Row>
              </Form.Item>
            </Row>
          </Col>
        </Row>
        <SubmitButton loading={loading} />
      </Form>
    </>
  );
};

export default Summary;

import { FC } from "react";
import { createUseStyles } from "react-jss";
import { MEDIA_QUERY } from "consts";
import { Steps } from "antd";
import { FormattedMessage } from "react-intl";
import { useSelector } from "hooks";
import { Skeleton } from "./components";

const useStyles = createUseStyles({
  steps: {
    overflowY: "auto",
    margin: "14px 0px",
    [`${MEDIA_QUERY.mobile}`]: {
      padding: 0,
      "& .ant-steps-item": {
        width: "100%",
        textAlign: "center",

        "& .ant-steps-item-icon": {
          margin: 0,
          marginInlineStart: "0 !important",
        },

        "& .ant-steps-item-content": {
          width: "100%",
          textAlign: "center",
        },
      },
    },
  },
});

interface ExtendedStepsProps {
  loading?: boolean;
  currentStep: number;
  steps: string[];
}

const ExtendedSteps: FC<ExtendedStepsProps> = ({
  currentStep,
  steps,
  loading,
}) => {
  const classes = useStyles();
  const mobile = useSelector((state) => state.appState.breakpoint.mobile);

  const step = currentStep + 1;

  if (loading) {
    return <Skeleton steps={steps} />;
  }

  return (
    <Steps
      size="small"
      current={currentStep}
      responsive={false}
      labelPlacement="vertical"
      className={classes.steps}
      items={
        mobile
          ? [
              {
                title: <FormattedMessage id={steps[currentStep]} />,
                description: `${step}/${steps.length}`,
                status: "process",
                icon: step,
              },
            ]
          : steps.map((title) => ({
              title: <FormattedMessage id={title} />,
            }))
      }
    />
  );
};

export default ExtendedSteps;

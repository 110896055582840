import { FC, useState } from "react";
import { getAccountSlug } from "utils";
import { useMutation, useNavigate, useDispatch } from "hooks";
import { routes, authAsyncActions } from "configs";
import {
  Card,
  Col,
  Divider,
  Form,
  Image,
  InputText,
  InputEmail,
  InputPassword,
  CatchAll,
  Button,
  NavLink,
  Row,
  Typography,
  VerifyPassword,
  notification,
} from "components";
import { createUseStyles } from "react-jss";
import { Client } from "types";

import { dashboardLogo } from "assets";

const { Paragraph, Text } = Typography;

const useStyles = createUseStyles({
  card: { width: "50%", maxWidth: 400 },
  outerCard: { height: "100%", backgroundColor: "#1677ff22" },
  row: {
    height: "100%",
  },
  col: {
    height: "100%",
  },
  form: { marginTop: 20 },
});

const COL_PROPS = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
};

const Register: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [confirmCodeSent, sendConfirmCode] = useState(false);
  const [form] = Form.useForm();
  const classes = useStyles();
  const accountSlug = getAccountSlug();

  const { mutate, isPending } = useMutation<
    Client,
    {
      firstName: string;
      lastName: string;
      email: string;
      password: string;
      accountSlug: string;
    }
  >({
    apiName: "client",
    path: "/register",
    method: "post",
    onSuccess: () => {
      dispatch(authAsyncActions.logOut());

      sendConfirmCode(true);
    },
    onError: (message) => {
      if (message.toLowerCase().indexOf("exists") !== -1) {
        notification.error({
          message,
        });

        navigate(routes.login, { replace: true });
      }
    },
  });

  if (!accountSlug) {
    return <CatchAll />;
  }

  if (confirmCodeSent) {
    const values = form.getFieldsValue(["password", "email"]);
    return <VerifyPassword {...values} />;
  }

  return (
    <Row className={classes.row}>
      <Col {...COL_PROPS} className={classes.col}>
        <Card className={classes.outerCard}>
          <Typography.Title level={2}>
            Welcome to MyCorpManager.
          </Typography.Title>
          <Divider />
          <Typography.Title level={5}>
            Your premier portal for the online setup and management of your
            corporation.
          </Typography.Title>
          <Divider />
          <Paragraph>
            With our easy-to-use application, you can quickly incorporate your
            Australian company and manage their details. You can also use our
            platform shortly to setup Discretionary (Family) Trusts, Unit
            Trusts, and Self-Managed Super Funds.
          </Paragraph>
          <Divider />
          <Paragraph>Login now or Register for a new account.</Paragraph>
          <Row justify="center" align="middle" className={classes.row}>
            <Image src={dashboardLogo} preview={false} />
          </Row>
        </Card>
      </Col>
      <Col {...COL_PROPS}>
        <Row justify="center" align="middle" className={classes.row}>
          <Card title="Register Now" className={classes.card}>
            <Form
              form={form}
              layout="vertical"
              className={classes.form}
              onFinish={(values) => {
                mutate({
                  ...values,
                  accountSlug,
                });
              }}
            >
              <Paragraph>
                Fill in your details below to instantly create a free
                <Text strong> MyCorpManager</Text> account.
              </Paragraph>
              <Divider />
              <InputText required name="firstName" label="common.first_name" />
              <InputText required name="lastName" label="common.last_name" />
              <InputEmail required />
              <InputPassword
                required
                name="password"
                label="password.password"
              />
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={isPending}
              >
                Register Now
              </Button>
              <NavLink to="/login">
                <Button style={{ width: "100%", marginTop: 4 }}>
                  Login Existing Account
                </Button>
              </NavLink>
            </Form>
          </Card>
        </Row>
      </Col>
    </Row>
  );
};

export default Register;

import { FC, ReactNode } from "react";
import { COLORS } from "consts";
import { createUseStyles } from "react-jss";
import { Card } from "antd";
import classNames from "classnames";

const useStyles = createUseStyles({
  card: {
    width: 240,
    transition: "box-shadow .3s",
    zIndex: 1,
    backfaceVisibility: "hidden",
  },
  selectedCard: {
    boxShadow: `
      0 1px 2px -2px ${COLORS.blue[1]},
      0 3px 6px 0 ${COLORS.blue[2]},
      0 5px 12px 4px ${COLORS.blue[2]}
    `,
  },
});

interface ExtendedCardProps {
  title: string;
  className?: string;
  description: ReactNode;
  selected: boolean;
  hoverable?: boolean;
  onClick?: () => void;
}

const ExtendedCard: FC<ExtendedCardProps> = ({
  title,
  description,
  selected,
  hoverable,
  className,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <Card
      className={classNames(classes.card, className, {
        [classes.selectedCard]: selected,
      })}
      hoverable={hoverable}
      onClick={onClick}
      cover={
        <img
          alt="example"
          src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
        />
      }
    >
      <Card.Meta title={title} description={description} />
    </Card>
  );
};

export default ExtendedCard;

import { Client, InitialData } from "types";
import { API } from "utils";

export const getInitialData = async (
  cognitoID: string,
): Promise<InitialData> => {
  const client: Client = await API.get({
    apiName: "client",
    path: `/${cognitoID}`,
  });

  const [account] = await Promise.all([
    API.get({
      apiName: "account",
      path: `/${client.accountID}`,
    }),
  ]);

  return {
    client,
    account,
  };
};

import { useMemo } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLIC_KEY } from "consts";
import { useSelector } from "./useReactRedux";

const useStripeScript = () => {
  const stripeAccount = useSelector(
    (state) => state.auth.account?.stripeID as string,
  );

  return useMemo(
    () => loadStripe(STRIPE_PUBLIC_KEY, { stripeAccount }),
    [stripeAccount],
  );
};

export default useStripeScript;

import { FC } from "react";
import { Form, DatePicker } from "antd";
import { useIntl } from "hooks";
import { DATE_FORMAT } from "consts";
import dayjs from "dayjs";
import * as duck from "./duck";

const InputDatePicker: FC<duck.Types.InputDatePickerProps> = ({
  label,
  name,
  dateFormat = DATE_FORMAT,
  allowClear = false,
  required = false,
  rules = [],
  isAfterToday,
  picker,
}) => {
  const intl = useIntl();

  return (
    <Form.Item
      label={label && intl.formatMessage({ id: label, defaultMessage: "" })}
      name={name}
      normalize={(dayjsValue) => dayjsValue && dayjsValue.toISOString()}
      getValueProps={(value) => value && { value: dayjs(value) }}
      rules={[
        {
          required,
          message: intl.formatMessage({ id: "validation.required" }),
        },
        {
          validator: (rule, value) => {
            if (!value || !isAfterToday) {
              return Promise.resolve();
            }

            if (dayjs(value).valueOf() > dayjs().valueOf()) {
              return Promise.reject(
                intl.formatMessage({ id: "validation.futureDate" }),
              );
            }

            return Promise.resolve();
          },
        },
        ...rules,
      ]}
    >
      <DatePicker
        placeholder={dateFormat}
        style={{ width: "100%" }}
        format={dateFormat}
        allowClear={allowClear}
        picker={picker}
      />
    </Form.Item>
  );
};
export default InputDatePicker;

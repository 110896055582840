import { FC } from "react";
import { Tabs } from "components";
import * as LC from "./components";
import { TabProps } from "./types";

interface CompanyTabsProps extends TabProps {
  cognitoID: string;
}

const CompanyTabs: FC<CompanyTabsProps> = ({ company, cognitoID }) => {
  return (
    <Tabs
      items={[
        {
          label: "Summary",
          key: "Summary",
          children: <LC.Summary company={company} />,
        },
        {
          label: "Address",
          key: "Address",
          children: <LC.Address company={company} />,
        },
        {
          label: "Documents",
          key: "Documents",
          children: (
            <LC.Documents cognitoID={cognitoID} companyID={company.id} />
          ),
        },
        {
          label: "Officeholders",
          key: "Officeholders",
          children: (
            <LC.Officeholders cognitoID={cognitoID} company={company} />
          ),
        },
        {
          label: "Shareholders",
          key: "Shareholders",
          children: (
            <LC.ShareHoldings cognitoID={cognitoID} company={company} />
          ),
        },
      ]}
    />
  );
};

export default CompanyTabs;

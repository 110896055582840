import { FC } from "react";
import {
  IndividualSelect,
  CompanySelect,
  StepButtons,
  Alert,
  Col,
  Form,
  Row,
  PreLoader,
} from "components";
import { COMMON_COL_PROPS, ROW_GUTTER } from "consts";
import {
  useForm,
  useMutation,
  useQuery,
  useIndividuals,
  useCompanies,
} from "hooks";
import { getQueryKey } from "utils";
import { Beneficiary } from "types";
import { Types } from "../../duck";

const MAX_COUNT = 6;

const Beneficiaries: FC<Types.DTStepProps> = ({
  discretionaryTrust,
  onNextClick,
  queryKey,
  cognitoID,
  ...props
}) => {
  const [form] = useForm();
  const urlPath = `/${cognitoID}/discretionary-trust/${discretionaryTrust.id}`;
  const { isPending, mutate: updateDT3 } = useMutation<
    any,
    {
      beneficiaries: Beneficiary[];
    }
  >({
    apiName: "client",
    path: `${urlPath}/step-${props.currentStep + 1}`,
    method: "patch",
    invalidateQueries: [queryKey],
    onSuccess: () => {
      onNextClick?.();
    },
  });

  const {
    isLoading,
    isRefetching,
    data: beneficiaries = [],
  } = useQuery<Beneficiary[]>({
    apiName: "client",
    path: `${urlPath}/beneficiaries`,
    queryKey: getQueryKey("beneficiaries", cognitoID, discretionaryTrust.id),
  });

  const { companies = [], companiesLoading } = useCompanies({
    registeredOnly: true,
  });
  const { individuals = [], individualsLoading } = useIndividuals();

  if (isLoading || isRefetching) {
    return <PreLoader />;
  }

  return (
    <Form<{
      individualIDs: number[];
      companyIDs: number[];
    }>
      form={form}
      layout="vertical"
      onValuesChange={() => {
        form.validateFields().catch(() => {
          //
        });
      }}
      initialValues={{
        ...discretionaryTrust,
        individualIDs: beneficiaries.reduce(
          (prev, curr) => [
            ...prev,
            ...(curr.individualID ? [curr.individualID] : []),
          ],
          [] as number[],
        ),
        companyIDs: beneficiaries.reduce(
          (prev, curr) => [
            ...prev,
            ...(curr.companyID ? [curr.companyID] : []),
          ],
          [] as number[],
        ),
      }}
      onFinish={({ individualIDs, companyIDs }) => {
        if (form.isFieldsTouched()) {
          const data =
            individualIDs.length > companyIDs.length
              ? individualIDs.map((itemID, index) => ({
                  individualID: itemID,
                  companyID: companyIDs[index] || null,
                }))
              : companyIDs.map((itemID, index) => ({
                  companyID: itemID,
                  individualID: individualIDs[index] || null,
                }));

          updateDT3({ beneficiaries: data });
        } else {
          onNextClick?.();
        }
      }}
    >
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <Form.Item noStyle dependencies={["companyIDs"]}>
            {({ getFieldValue }) => {
              const value = getFieldValue("companyIDs");

              return (
                <IndividualSelect
                  multi
                  name="individualIDs"
                  parentForm={form}
                  required={!value.length}
                  maxCount={MAX_COUNT - value.length}
                  fetchOptions={{
                    options: individuals,
                    loading: individualsLoading,
                  }}
                  drawerRequiredFields={{
                    firstName: true,
                    lastName: true,
                    addressID: true,
                    dateOfBirth: true,
                  }}
                />
              );
            }}
          </Form.Item>
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <Form.Item noStyle dependencies={["individualIDs"]}>
            {({ getFieldValue }) => {
              const value = getFieldValue("individualIDs");

              return (
                <CompanySelect
                  multi
                  name="companyIDs"
                  parentForm={form}
                  required={!value.length}
                  maxCount={MAX_COUNT - value.length}
                  fetchOptions={{
                    options: companies,
                    loading: companiesLoading,
                  }}
                  drawerRequiredFields={{
                    name: true,
                    acn: true,
                    typeID: true,
                  }}
                />
              );
            }}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item noStyle dependencies={["individualIDs", "companyIDs"]}>
        {({ getFieldsValue }) => {
          const { individualIDs, companyIDs } = getFieldsValue([
            "individualIDs",
            "companyIDs",
          ]);

          return (
            <Alert
              message="Minimum: 1. Maximum: 6"
              style={{ marginTop: 14 }}
              type={
                individualIDs.length || companyIDs.length ? "success" : "error"
              }
            />
          );
        }}
      </Form.Item>
      <StepButtons {...props} loading={isPending} />
    </Form>
  );
};

export default Beneficiaries;

import * as React from "react";
import { Form, Radio, RadioGroupProps, FormItemProps } from "antd";
import { FormattedMessage } from "react-intl";
import { INPUT_GROUP_OPTIONS } from "consts";
import { XOR } from "types";

interface InputGroupProps
  extends Omit<RadioGroupProps, "children" | "name" | "options">,
    Omit<FormItemProps, "label"> {
  options?: {
    label: string;
    translate?: boolean;
    value: boolean | string | number;
  }[];
}

interface WithFormProps extends InputGroupProps {
  label?: string;
  name: string | string[];
}

const InputGroup: React.FC<
  XOR<WithFormProps, InputGroupProps & { withoutWrapper: boolean }>
> = ({
  name,
  label,
  withoutWrapper,
  options,
  optionType = "button",
  buttonStyle = "solid",
  className,
  onChange,
  value,
}) => {
  const commonProps = {
    className,
    optionType,
    buttonStyle,
    options: (options || INPUT_GROUP_OPTIONS).map(
      ({ translate = true, ...opt }) => ({
        ...opt,
        label: translate ? <FormattedMessage id={opt.label} /> : opt.label,
      }),
    ),
  };

  if (withoutWrapper) {
    return <Radio.Group {...commonProps} onChange={onChange} value={value} />;
  }

  return (
    <Form.Item
      name={name}
      label={label && <FormattedMessage id={label} />}
      getValueProps={
        !options
          ? (propsValue) => ({
              value: !!propsValue,
            })
          : undefined
      }
    >
      <Radio.Group {...commonProps} />
    </Form.Item>
  );
};

export default InputGroup;

import { FC } from "react";
import {
  InputNumber as ANTDInputNumber,
  InputNumberProps,
  Form,
  FormItemProps,
} from "antd";
import { FormattedMessage } from "react-intl";
import { formatToCurrency } from "utils";
import { createUseStyles } from "react-jss";
import classNames from "classnames";

interface FormItemInputNumberProps
  extends FormItemProps,
    Pick<InputNumberProps, "min" | "max" | "precision" | "step"> {
  label?: string;
  className?: string;
  greaterThan?: number;
  equalOrMore?: number;
  lessThan?: number;
  format?: boolean;
}

const useStyles = createUseStyles({
  inputNumber: {
    width: "100%",
  },
});

const FormItemInputNumber: FC<FormItemInputNumberProps> = ({
  name,
  rules = [],
  required,
  label,
  className,
  min = 0,
  max,
  precision,
  greaterThan,
  lessThan,
  equalOrMore,
  format,
  step,
}) => {
  const classes = useStyles();

  return (
    <Form.Item
      label={label && <FormattedMessage id={label} />}
      name={name}
      rules={[
        {
          required,
          message: <FormattedMessage id="validation.required" />,
        },
        {
          validator(rule, value) {
            if (greaterThan !== undefined && value <= greaterThan) {
              return Promise.reject(
                <FormattedMessage
                  id="validation.greaterThan"
                  values={{ n: greaterThan }}
                />,
              );
            }

            if (equalOrMore !== undefined && value < equalOrMore) {
              return Promise.reject(
                <FormattedMessage
                  id="validation.equalOrMore"
                  values={{ n: equalOrMore }}
                />,
              );
            }

            if (lessThan !== undefined && value > lessThan) {
              return Promise.reject(
                <FormattedMessage
                  id="validation.lessThan"
                  values={{ n: lessThan }}
                />,
              );
            }

            return Promise.resolve();
          },
        },
        ...rules,
      ]}
    >
      <ANTDInputNumber
        min={min}
        max={max}
        step={step}
        decimalSeparator="."
        precision={precision}
        className={classNames(classes.inputNumber, className)}
        formatter={!format ? undefined : formatToCurrency}
        parser={(value) => value?.replace(/\$\s?|(,*)/g, "") || 0}
      />
    </Form.Item>
  );
};

export default FormItemInputNumber;

import * as Types from "./types";

export const INITIAL_VALUES: Types.StreetAddressResult = {
  countryID: null,
  streetTypeID: null,
  unitNumber: "",
  streetNumber: "",
  streetName: "",
  state: "",
  suburb: "",
  postcode: "",
};
